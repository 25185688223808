import ConfirmDialog from "../ConfirmDialog";
import React from "react";
import {useMutation} from "@apollo/client";
import {DELETE_SUGGESTION} from "../../../apollo/mutations";
import {useSnackBar} from "@mcity/core/lib/components/providers/SnackBarProvider";

export const SuggestionDeleteConfirmDialog = ({hideDialog, selectedSuggestionIdToDelete, refetch, closeModal = () => {}}) => {
    const { showSnackBar } = useSnackBar()
    const [deleteSuggestion] = useMutation(DELETE_SUGGESTION)

    return (
        <ConfirmDialog
            text="Vai tiešām vēlaties dzēst šo ierosinājumu?"
            onClose={hideDialog}
            onAccept={() => {
                deleteSuggestion({
                    variables: {
                        id: selectedSuggestionIdToDelete,
                    },
                })
                    .then(response => {
                        if (response?.data?.incidents?.delete) {
                            refetch()
                            showSnackBar({
                                text: 'Ierosinājums izdzēsts',
                            })
                            closeModal()
                        }
                    })
                    .catch(err =>
                        showSnackBar({
                            severity: 'error',
                            text: `kļūda! ${JSON.stringify(err)}`,
                        }),
                    )
                    .finally(() => {
                        hideDialog()
                    })
            }}
            onCancel={hideDialog}
        />
    )
}