import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UPDATE_SUGGESTION } from '../../../apollo/mutations'

const UNPROCESSED = 'UNPROCESSED'
export const MARKED_FOR_UPLOAD = 'MARKED_FOR_UPLOAD'
const WILL_NOT_UPLOAD = 'WILL_NOT_UPLOAD'

export const integrationStatusLabels = {
  [UNPROCESSED]: 'Neapstrādāts',
  [MARKED_FOR_UPLOAD]: 'Nodots uz Lietvari',
  [WILL_NOT_UPLOAD]: 'Netiks sūtīts',
}

export const integrationStatusCompareWeights = {
  [UNPROCESSED]: 0,
  [MARKED_FOR_UPLOAD]: 1,
  [WILL_NOT_UPLOAD]: 2,
}

export const INTEGRATION_STATUSES = [
  UNPROCESSED,
  MARKED_FOR_UPLOAD,
  WILL_NOT_UPLOAD,
]

export default function SuggestionIntegrationStatusRenderer({
  id,
  integrationStatus,
  onError,
  onSuccess,
}) {
  const [value, setValue] = useState(integrationStatus)
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = event => {
    updateSuggestion({
      variables: {
        id,
        input: {
          integrationStatus: event.target.value,
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          setValue(data.incidents.update.integrationStatus)
          onSuccess({ message: `Integrācijas status nomainīts` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return (
    <Select value={value} onChange={onChange}>
      {INTEGRATION_STATUSES.map(integrationStatus => (
        <MenuItem key={integrationStatus} value={integrationStatus}>
          {integrationStatusLabels[integrationStatus]}
        </MenuItem>
      ))}
    </Select>
  )
}
