import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UPDATE_SUGGESTION } from '../../../apollo/mutations'

const TYPE_PROBLEM = 'PROBLEM'
const TYPE_SUGGESTION = 'SUGGESTION'

export const typesLabels = {
  [TYPE_PROBLEM]: 'Problēma',
  [TYPE_SUGGESTION]: 'Ierosinājums',
}

export const typeCompareWeights = {
  [TYPE_PROBLEM]: 0,
  [TYPE_SUGGESTION]: 1,
}

export const TYPES = [TYPE_PROBLEM, TYPE_SUGGESTION]

export default function SuggestionTypeRenderer({
  id,
  type,
  onError,
  onSuccess,
}) {
  const [value, setValue] = useState(type)
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = event => {
    updateSuggestion({
      variables: {
        id,
        input: {
          incidentType: event.target.value,
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          setValue(data.incidents.update.incidentType)
          onSuccess({ message: `Tips nomainīts` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return (
    <Select value={value} onChange={onChange}>
      {TYPES.map(type => (
        <MenuItem key={type} value={type}>
          {typesLabels[type]}
        </MenuItem>
      ))}
    </Select>
  )
}
