import {useQuery} from "@apollo/client";
import {GET_NOTIFICATIONS_COUNT, LIST_NOTIFICATIONS} from "../apollo/queries";
import {useEffect, useState} from "react";
import {format} from "date-fns";
import {lv} from "date-fns/locale";
import {stripHtml, truncate} from "@mcity/core/lib/utils/miscUtils";

const reshapedNotification = ({id, dateAdded, dateFrom, dateTo, details, sendNotifications, ...transaction}) => {
    const displayableDetails = details.find((d) => {return d.languageId === 1})
    return {
        id: id,
        title: displayableDetails.title,
        dateFrom: format(new Date(dateFrom), 'P', { locale: lv }),
        dateTo: format(new Date(dateTo), 'P', { locale: lv }),
        dateAdded: format(new Date(`${dateAdded}Z`), 'dd/MM/yyyy hh:mm', {
            locale: lv,
        }),
        description: truncate(stripHtml(displayableDetails.description)),
        sendNotifications: sendNotifications ? 'Jā' : 'Nē',
        ...transaction,
    }
}

export const useFetchAndReshapeNotifications = (skip, limit, orderBy, orderAscending) => {
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const {loading: loadingCount, data: dataCount, refetch: refetchCount, error: errorCount} = useQuery(GET_NOTIFICATIONS_COUNT)

    const {
        error: errorNotifications,
        data: dataNotifications,
        loading: loadingNotifications,
        refetch: refetchNotifications
    } = useQuery(LIST_NOTIFICATIONS, {
        variables: {
            limit,
            skip,
            orderBy,
            orderAscending,
        },
    })

    useEffect(() => {
        setLoading(true)
        if(!loadingNotifications && !loadingCount) {
            if(errorNotifications || errorCount) {
                setError("Kļūda iegūstot datus")
            } else {
                const newRows = dataNotifications?.messages?.messages?.messages?.map(reshapedNotification)
                setRows(newRows || [])
                setCount(dataCount?.messages?.messages?.messagesTotalCount || 0)
            }
            setLoading(false)
        }
    }, [loadingNotifications, loadingCount, dataNotifications, dataCount])

    const refetch = () => {
        refetchNotifications();
        refetchCount();
    }

    return {rows, loading, count, refetch, error}
}
