const endpoint = process.env.ENDPOINT || 'https://lietotne.liepaja.lv'
const cityCenter = [21.0108, 56.5047];
const mapBoxKey = "pk.eyJ1IjoibGlldG90bmVsaWVwYWphIiwiYSI6ImNraDY0NmFtZTBha3gzMXVpb2kwcGw3N24ifQ.PTqUvUrwApNgRiixbKcCiQ";

module.exports = {
  graphqlUri: `${endpoint}/graphql`,
  uploadUrl: `${endpoint}/rest/upload_file`,
  downloadUrl: `${endpoint}/rest/download_file`,
  downloadWithTokenUrl: `${endpoint}/rest/download_file_with_token`,
  cityCenter,
  mapBoxKey,
}
