import React from 'react'
import { PASSWORD_AUTH } from '../../apollo/queries'
import { useApolloClient } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const Context = React.createContext(null)

export const CREDENTIALS_USER_TOKEN = 'credentials-user-token'
export const CREDENTIALS_USER = 'credentials-user'
export function usePasswordAuth() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'usePasswordAuth must be used within a PasswordAuthProvider',
    )
  }
  return context
}

export default function PasswordAuthProvider({ children }) {
  const client = useApolloClient()
  const { t } = useTranslation()
  const userJson = localStorage.getItem(CREDENTIALS_USER)
  const userObj = userJson && JSON.parse(userJson)
  const [token, setToken] = React.useState(
    localStorage.getItem(CREDENTIALS_USER_TOKEN),
  )
  const [userId, setUserId] = React.useState(userObj?.userId)
  const [username, setUsername] = React.useState(userObj?.username)
  const [firstName, setFirstName] = React.useState(userObj?.firstName)
  const [lastName, setLastName] = React.useState(userObj?.lastName)
  const [params, setParams] = React.useState(userObj?.params)

  const login = (username, password) => {
    return client
      .query({
        variables: {
          username: username,
          password: password,
        },
        fetchPolicy: 'network-only',
        query: PASSWORD_AUTH,
      })
      .then(({ data }) => {
        if (data?.passwordAuth?.login) {
          const { token, params, usernameId } = data.passwordAuth.login
          const firstNameParam = params.find(p => p.key === 'name')
          const lastNameParam = params.find(p => p.key === 'surname')
          const firstName = firstNameParam?.value
          const lastName = lastNameParam?.value
          const userObject = {
            userId: usernameId,
            username,
            firstName,
            lastName,
            params,
          }
          localStorage.setItem(CREDENTIALS_USER_TOKEN, token)
          localStorage.setItem(CREDENTIALS_USER, JSON.stringify(userObject))

          setToken(token)
          setUsername(username)
          setFirstName(firstName)
          setLastName(lastName)
          setUserId(usernameId)
          setParams(params)
          return userObject
        } else {
          throw Error('Kļūda')
        }
      })
      .catch(e => {
        if (e.message === 'Network error: Failed to fetch')
          throw Error('Tīkla kļūda, mēģiniet vēlreiz')
        else throw Error('Nepareizs lietotājvārds vai parole')
      })
  }

  const logout = () => {
    localStorage.removeItem(CREDENTIALS_USER_TOKEN)
    localStorage.removeItem(CREDENTIALS_USER)
    setToken(null)
    setLastName(null)
    setFirstName(null)
    setUsername(null)
    setUserId(null)
    setParams(null)
  }

  const isTrue = param => {
    return (
      params &&
      Object.values(params).find(p => p.key === param)?.value === 'true'
    )
  }

  return (
    <Context.Provider
      value={{
        token,
        login,
        logout,
        userId,
        firstName,
        lastName,
        username,
        params,
        isTrue,
      }}
    >
      {children}
    </Context.Provider>
  )
}
