import React from 'react'
import { useQuery } from '@apollo/client'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import DifferencesRenderer from '../../elements/history/DifferencesRenderer'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'

import Divider from '@material-ui/core/Divider'
import SearchIcon from '@material-ui/icons/Search'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { KeyboardDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import InfoDialog from '../../elements/InfoDialog'

import useExportXlsx from '../../elements/useExportXlsx'
import { GET_USERNAMES } from '@mcity/core/lib/gql/queries'

const HistoryOrderBy = {
  CURRENT_ENTITY_ID: 'id',
  KIND: 'kind',
  IP_ADDRESS: 'audit.ipAddress',
  ACTION_TIME: 'audit.actionTime',
  USER_ID: 'audit.userId',
}

const kindLabels = {
  UPDATED: 'Atjaunots',
  CREATED: 'Izveidots',
  DELETED: 'Dzēsts',
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchField: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    float: 'right',
  },
  formControl: {
    margin: 0,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function HistoryBase({
  query,
  queryPath,
  keyLabels,
  keyRenderers,
  title = 'Vēsture',
  showOnlyDefinedLabels = false,
}) {
  const classes = useStyles()

  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)
  const [sortedBy, setSortedBy] = React.useState(HistoryOrderBy.ACTION_TIME)
  const [orderDesc, setOrderDesc] = React.useState(false)
  const [exportData, setExportData] = React.useState()
  const [exportMerges, setExportMerges] = React.useState()
  const [alert, setAlert] = React.useState(null)

  const [filter, setFilter] = React.useState(null)
  const [filterEntityId, setFilterEntityId] = React.useState('')
  const [filterKind, setFilterKind] = React.useState('')
  const [filterIpAddress, setFilterIpAddress] = React.useState('')
  const [filterUserId, setFilterUserId] = React.useState('')
  const [filterActionTime, setFilterActionTime] = React.useState(null)
  const [openDifferences, setOpenDifferences] = React.useState(null)
  const [userIdToUsername, setUserIdToUsername] = React.useState({})

  const [skipFetchinExportData, setSkipFetchinExportData] = React.useState(true)

  const columns = [
    {
      field: 'baseEntityId',
      title: 'ID',
      sortId: HistoryOrderBy.CURRENT_ENTITY_ID,
      filterType: 'text',
      filterValue: filterEntityId,
      onFilterChange: setFilterEntityId,
    },
    {
      title: 'Darbība',
      sortId: HistoryOrderBy.KIND,
      filterType: 'select',
      filterValue: filterKind,
      filterSelectValues: kindLabels,
      onFilterChange: setFilterKind,
    },
    {
      title: 'Lietotājs',
      sortId: HistoryOrderBy.USER_ID,
      filterType: 'text',
      filterValue: filterUserId,
      onFilterChange: setFilterUserId,
    },
    {
      title: 'Izmaiņu laiks',
      sortId: HistoryOrderBy.ACTION_TIME,
      filterType: 'date',
      filterValue: filterActionTime,
      onFilterChange: setFilterActionTime,
    },
    {
      title: 'IP Adrese',
      sortId: HistoryOrderBy.IP_ADDRESS,
      filterType: 'text',
      filterValue: filterIpAddress,
      onFilterChange: setFilterIpAddress,
    },
    {
      field: 'differences',
      title: 'Atšķirības',
      filterType: 'skipCell',
    },
  ]

  const { data } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      orderBy: sortedBy,
      orderAscending: orderDesc,
      filter: filter,
    },
  })

  const { data: dataForExport } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 10000,
      skip: 0,
      orderBy: sortedBy,
      orderAscending: orderDesc,
      filter: filter,
    },
    skip: skipFetchinExportData,
  })

  useQuery(GET_USERNAMES, {
    onCompleted: data => {
      const users = data?.authorizationUsers?.listUsersByAuthenticator
      const tmp = {}
      if (users) {
        users.forEach(user => {
          tmp[user.id] = user.uniqueId
        })
      }
      setUserIdToUsername(tmp)
    },
    onError: err => {
      console.log('Error getting users', err)
    },
  })

  const createHistory = inputData => {
    const pathArray = queryPath.split('.')
    let result = inputData
    pathArray.forEach(p => {
      result = result?.[p]
    })
    return result?.history
  }

  const history = React.useMemo(() => {
    return createHistory(data)
  }, [data, queryPath])

  const onExportCompleted = () => {
    setExportData()
  }

  const { loading: loadingExport } = useExportXlsx(
    exportData,
    onExportCompleted,
    title,
    title,
    title,
    exportMerges,
  )

  React.useEffect(() => {
    const items = history?.list
    const totalCount = history?.totalCount
    if (items) {
      setRows(
        items.map(({ __typename, audit, ...item }) => ({
          ...item,
          audit: {
            ...audit,
            userId: audit.userId && userIdToUsername[audit.userId] || audit.userId
          }
        })),
      )
      setTotalCount(totalCount)
    }
  }, [data, userIdToUsername])

  React.useEffect(() => {
    if (!skipFetchinExportData) {
      doExport()
      setSkipFetchinExportData(true)
    }
  }, [dataForExport])

  const addAndFilter = (filter, field, value) => {
    return {
      key: field,
      value: value,
      flip: false,
      approximate: false,
      and: filter,
    }
  }

  const onSearchClick = () => {
    let filter = null
    if (filterKind !== '') {
      filter = addAndFilter(filter, 'kind', filterKind)
    }
    if (filterIpAddress !== '') {
      filter = addAndFilter(filter, 'audit.ipAddress', filterIpAddress)
    }
    if (filterUserId !== '') {
      filter = addAndFilter(filter, 'audit.userId', filterUserId)
    }
    if (filterActionTime && filterActionTime !== '') {
      filter = addAndFilter(filter, 'audit.actionTime', filterActionTime)
    }
    if (filterEntityId !== '') {
      filter = addAndFilter(filter, 'entities.base_id', filterEntityId)
    }
    setFilter(filter)
  }

  const onCloseAlert = () => {
    setAlert(null)
  }

  const render = (value, key) =>
    keyRenderers && keyRenderers[key] ? keyRenderers[key](value) : value

  const doExport = () => {
    let row = 1
    const merges = []
    const exportHistory = createHistory(dataForExport)
    const formattedData = exportHistory?.list.flatMap(item => {
      const differences = showOnlyDefinedLabels
        ? item.differences.filter(({ key }) => keyLabels[key])
        : item.differences
      if (item?.differences.length > 1) {
        merges.push({
          s: { r: row, c: 0 },
          e: { r: row + differences.length - 1, c: 0 },
        })
        merges.push({
          s: { r: row, c: 1 },
          e: { r: row + differences.length - 1, c: 1 },
        })
        merges.push({
          s: { r: row, c: 2 },
          e: { r: row + differences.length - 1, c: 2 },
        })
        merges.push({
          s: { r: row, c: 3 },
          e: { r: row + differences.length - 1, c: 3 },
        })
        merges.push({
          s: { r: row, c: 4 },
          e: { r: row + differences.length - 1, c: 4 },
        })
      }
      if (item?.differences.length > 0) {
        return differences.map(
          ({ key, previousValue, currentValue }, index) => {
            row++
            return [
              !index ? item.baseEntityId : '',
              !index ? kindLabels[item.kind] : '',
              !index ? item?.audit.userId : '',
              !index && item?.audit?.actionTime
                ? format(
                    new Date(item.audit.actionTime),
                    'dd.MM.yyyy HH:mm:ss',
                    {
                      locale: lv,
                    },
                  )
                : '',
              !index ? item?.audit.ipAddress : '',
              keyLabels[key] || key,
              render(previousValue, key) || '',
              render(currentValue, key),
            ]
          },
        )
      } else {
        row++
        return [
          [
            item.baseEntityId,
            kindLabels[item.kind],
            item?.audit.userId,
            item?.audit?.actionTime
              ? format(new Date(item.audit.actionTime), 'dd.MM.yyyy HH:mm:ss', {
                  locale: lv,
                })
              : '',
            item?.audit.ipAddress,
            '',
            '',
            '',
          ],
        ]
      }
    })
    setExportMerges(merges)
    setExportData([
      [
        'ID',
        'Darbība',
        'Lietotājs',
        'Izmaiņu laiks',
        'IP Adrese',
        'Atšķirīgais lauks',
        'Mainīts no',
        'Mainīts uz',
      ],
      ...formattedData,
    ])
  }

  return (
    <>
      {openDifferences && (
        <InfoDialog title="Atšķirības" setOpen={setOpenDifferences}>
          <DifferencesRenderer
            differences={openDifferences}
            keyLabels={keyLabels}
            keyRenderers={keyRenderers}
            showOnlyDefinedLabels={showOnlyDefinedLabels}
          />
        </InfoDialog>
      )}
      <Paper className={classes.root}>
        <Snackbar
          open={alert !== null}
          autoHideDuration={6000}
          onClose={onCloseAlert}
        >
          <MuiAlert
            onClose={onCloseAlert}
            severity={alert ? alert.severity : 'info'}
          >
            {alert ? alert.message : ''}
          </MuiAlert>
        </Snackbar>
        <TableContainer component={Paper} className={classes.table}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(
                  col =>
                    col.filterType !== 'skipCell' && (
                      <TableCell key={col.field}>
                        {col.filterType === 'text' && (
                          <TextField
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                            onChange={val =>
                              col.onFilterChange(val.target.value)
                            }
                            value={col.filterValue}
                          />
                        )}
                        {col.filterType === 'date' && (
                          <KeyboardDatePicker
                            autoOk
                            size="small"
                            variant="inline"
                            inputVariant="outlined"
                            value={col.filterValue}
                            onChange={value =>
                              col.onFilterChange(value ? value.format() : value)
                            }
                            format="DD.MM.YYYY"
                            InputAdornmentProps={{ position: 'start' }}
                            className={classes.formControl}
                          />
                        )}
                        {col.filterType === 'select' && (
                          <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <Select
                              value={col.filterValue}
                              onChange={val =>
                                col.onFilterChange(val.target.value)
                              }
                            >
                              <MenuItem value="">&nbsp;</MenuItem>
                              {Object.keys(col.filterSelectValues).map(
                                (key, index) => (
                                  <MenuItem key={index} value={key}>
                                    {col.filterSelectValues[key]}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </TableCell>
                    ),
                )}
                <TableCell colSpan={5}>
                  <Paper component="form" className={classes.searchField}>
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={() => onSearchClick()}
                    >
                      <SearchIcon />
                    </IconButton>
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    {loadingExport ? (
                      <CircularProgress />
                    ) : (
                      <IconButton
                        color="primary"
                        className={classes.iconButton}
                        aria-label="export"
                        title="Export XLSX"
                        onClick={() => setSkipFetchinExportData(false)}
                      >
                        <SystemUpdateAltIcon />
                      </IconButton>
                    )}
                  </Paper>
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map(col => (
                  <TableCell key={col.field}>
                    {col.sortId ? (
                      <TableSortLabel
                        active={col.sortId === sortedBy}
                        direction={orderDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          if (col.sortId === sortedBy) setOrderDesc(!orderDesc)
                          else setOrderDesc(false)

                          setSortedBy(col.sortId)
                        }}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.baseEntityId}</TableCell>
                  <TableCell>{kindLabels[row.kind]}</TableCell>
                  <TableCell>{row?.audit?.userId ? row.audit.userId : '-'}</TableCell>
                  <TableCell>
                    {row?.audit?.actionTime
                      ? format(
                          new Date(row.audit.actionTime),
                          'dd.MM.yyyy HH:mm:ss',
                          {
                            locale: lv,
                          },
                        )
                      : ''}
                  </TableCell>
                  <TableCell>{row.audit.ipAddress}</TableCell>
                  <TableCell className={classes.title}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setOpenDifferences(row.differences)}
                    >
                      Atvērt
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => {
              setRowsPerPage(Number(event.target.value))
              setPage(0)
            }}
            onChangePage={(_, page) => setPage(page)}
            labelRowsPerPage="Rindas lapā"
            labelDisplayedRows={({from, to, count}) => `${from}-${to} no ${count}`}
          />
        </TableContainer>
      </Paper>
    </>
  )
}
