import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import routes from '../../routes/routes'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { usePasswordAuth } from '../providers/PasswordAuthProvider'
import { useHistory } from 'react-router-dom'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PersonIcon from '@material-ui/icons/Person'
import ViewListIcon from '@material-ui/icons/ViewList'
import HistoryIcon from '@material-ui/icons/History'
import PeopleIcon from '@material-ui/icons/People'
import TopBarMenuButton from '@mcity/core/lib/components/elements/topbar/TopBarMenuButton'
import { useLiepajaAdminAuth } from '../providers/LiepajaAdminAuthProvider'
import ChangeOwnPasswordDialog from '../elements/ChangeOwnPasswordDialog'
import ErrorBoundary from '@mcity/liepaja-core/src/components/elements/error/ErrorBoundary'
import { useAppState } from '@mcity/liepaja-core/src/components/providers/AppStateProvider'
import { ErrorController } from '@mcity/liepaja-core/src/components/elements/error/ErrorController'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoutBox: {
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
}))

export default function TopBar({ children }) {
  const classes = useStyles()
  const history = useHistory()
  const { logout } = usePasswordAuth()
  const { error } = useAppState()

  const {
    isNotificationAdmin,
    isSuggestionsAdmin,
    isAuditAdmin,
    isUserManagementAdmin,
    isActive,
    username,
  } = useLiepajaAdminAuth()

  const [openUserWindow, setOpenUserWindow] = useState(false)

  const notificationItems = [
    { route: routes.notifications.list, displayName: 'Saraksts' },
  ]

  const suggestionsDropDownItems = [
    { route: routes.suggestions.map, displayName: 'Karte' },
    { route: routes.suggestions.list, displayName: 'Saraksts' },
  ]

  const auditDropDownItems = [
    { route: routes.history.notifications, displayName: 'Paziņojumu vēsture' },
    { route: routes.history.suggestions, displayName: 'Ierosinājumu vēsture' },
  ]

  const userManagementItems = [
    { route: routes.user_management, displayName: 'Lietotāju Administrēšana' },
  ]

  return (
    <>
      {openUserWindow && (
        <ChangeOwnPasswordDialog
          setOpen={setOpenUserWindow}
          userInfo={{
            username,
            isUserManagementAdmin,
            isAuditAdmin,
            isNotificationAdmin,
            isSuggestionsAdmin,
            isActive,
          }}
        />
      )}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Liepajas admin
          </Typography>
          <>
            {isNotificationAdmin && (
              <TopBarMenuButton
                startIcon={<NotificationsIcon />}
                items={notificationItems}
              >
                Paziņojumi
              </TopBarMenuButton>
            )}
            {isSuggestionsAdmin && (
              <TopBarMenuButton
                startIcon={<ViewListIcon />}
                items={suggestionsDropDownItems}
              >
                Ierosinājumi
              </TopBarMenuButton>
            )}
            {isAuditAdmin && (
              <TopBarMenuButton
                startIcon={<HistoryIcon />}
                items={auditDropDownItems}
              >
                Darbību audits
              </TopBarMenuButton>
            )}
            {isUserManagementAdmin && (
              <TopBarMenuButton
                startIcon={<PeopleIcon />}
                items={userManagementItems}
              >
                Lietotāju administrēšana
              </TopBarMenuButton>
            )}
          </>
          <Button
            color="inherit"
            className={classes.menuButton}
            startIcon={<PersonIcon />}
            onClick={() => {
              setOpenUserWindow(true)
            }}
          />
          <Button
            color="inherit"
            className={classes.menuButton}
            startIcon={<ExitToAppIcon />}
            onClick={() => {
              logout()
              history.push(routes.login)
            }}
          />
        </Toolbar>
      </AppBar>
      <ErrorBoundary>
        <ErrorController error={error}>
          {children}
        </ErrorController>
      </ErrorBoundary>
    </>
  )
}
