import React from 'react'
import {useQuery} from "@apollo/client";
import {GET_USER_BY_ID} from "../../../apollo/queries";

export default function SuggestionMoreInfo({
  description,
  contactInfo,
  lat,
  lng,
  userId
}) {

    const { loading, data } = useQuery(GET_USER_BY_ID, {
        variables: {
            id: userId,
        },
    })

    if(loading) {
        return <p>Sagatavo informāciju...</p>
    }

  return (
    <p>
      <b>Apraksts</b>
      <br />
      {description || '-'} <br />
      <br />
      <b>Kontaktinformācija</b> <br />
      {contactInfo || '-'} <br />
      <br />
      <b>Koordinātas</b> <br />
      {`${lat} ${lng}`} <br />
      <br />
      <b>Reģistrācijas telefona numurs</b> <br />
      {data?.authorizationUsers?.userById?.uniqueId || '-'} <br />
    </p>
  )
}
