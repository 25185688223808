import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client'
import InfoDialog from '../InfoDialog'

import { GET_FEEDBACK } from '../../../apollo/queries'
import Loader from '@mcity/core/lib/components/elements/Loader'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  comments: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400,
  },
  text: {
    fontSize: 18,
  },
}))

export default function NotificationFeedbackDialog({ setOpen, feedback }) {
  const classes = useStyles()

  const data = React.useMemo(() => {
    if (feedback) {
      const positive_count = feedback.filter(val => val.rating === true).length
      const comments = feedback
        .filter(val => val.rating === false)
        .map(val => val.comment)
      const negative_count = comments.length
      const uniqueCommentsWithCounts = []
      comments.map(comment => {
        const index = uniqueCommentsWithCounts.findIndex(
          commentInfo => comment === commentInfo.comment,
        )
        if (index < 0) {
          uniqueCommentsWithCounts.push({
            comment,
            count: 1,
          })
        } else {
          uniqueCommentsWithCounts[index].count += 1
        }
      })
      uniqueCommentsWithCounts.sort((prev, next) => next.count - prev.count)
      return {
        positive_count,
        negative_count,
        comments: uniqueCommentsWithCounts,
      }
    } else {
      return {
        positive_count: 0,
        comments: [],
      }
    }
  }, [feedback])

  return (
    <>
      <InfoDialog title="Atsauksmes" setOpen={setOpen}>
        <Box
          display="flex"
          flexDirection="column"
          spacing={3}
          className={classes.text}
          mb={1}
        >
          Pozitīvi vērtējumi: {data.positive_count}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          spacing={3}
          className={classes.text}
          mb={3}
        >
          Negatīvi vērtējumi: {data.negative_count}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          spacing={3}
          className={classes.text}
          mb={1}
        >
          Komentāri:
        </Box>
        <List className={classes.comments} subheader={<li />}>
          {data.comments.map((comment, index) => (
            <>
              <Divider component="li" />
              <ListItem key={index}>
                <ListItemText
                  primary={`${comment.comment} (${comment.count})`}
                />
              </ListItem>
            </>
          ))}
        </List>
      </InfoDialog>
    </>
  )
}
