import { include } from 'named-urls'

export default {
  home: '/',
  login: '/login',
  notifications: include('/notifications', {
    list: 'list',
  }),
  suggestions: include('/suggestions', {
    list: 'list',
    map: 'map',
    details: 'details',
  }),
  history: include('/history', {
    notifications: 'notifications',
    suggestions: 'suggestions',
  }),
  user_management: '/user-management',
}
