import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import SuggestionStatusRenderer from './SuggestionStatusRenderer'
import SuggestionTypeRenderer from './SuggestionTypeRenderer'
import SuggestionIntegrationStatusRenderer from './SuggestionIntegrationStatusRenderer'
import SuggestionDueDateRenderer from './SuggestionDueDateRenderer'
import SuggestionCommentRenderer from './SuggestionCommentRenderer'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import SuggestionActionRenderer from './SuggestionActionRenderer'

const useStyles = makeStyles(theme => ({
  description: {
    maxWidth: 600,
    minWidth: 200,
  },
}))

export default function SuggestionEdit({
  suggestion,
  onDelete,
  onSuccess,
  onError,
  children,
  openDescription = () => {},
}) {
  const classes = useStyles()
  return (
    <TableRow key={suggestion.id}>
      {children}
      <TableCell>{suggestion.id}</TableCell>
      <TableCell>
        <SuggestionStatusRenderer
          id={suggestion.id}
          status={suggestion.status}
          integrationStatus={suggestion.integrationStatus}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell>
        <SuggestionTypeRenderer
          id={suggestion.id}
          type={suggestion.incidentType}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell>
        {format(new Date(suggestion.creationDate), 'P p', { locale: lv })}
      </TableCell>
      <TableCell className={classes.title}>{suggestion.title}</TableCell>
      <TableCell className={classes.title}>
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            openDescription({
              description: suggestion.description,
              contactInfo: suggestion.contactInfo,
              lat: suggestion.lat,
              lng: suggestion.lng,
              userId: suggestion.userId,
            })
          }
        >
          Atvērt
        </Button>
      </TableCell>
      <TableCell style={{ minWidth: 150 }}>{suggestion.address}</TableCell>
      {/* <TableCell>{suggestion.userId}</TableCell> */}
      <TableCell style={{ minWidth: 160 }}>
        <SuggestionDueDateRenderer
          id={suggestion.id}
          plannedDueDate={suggestion.plannedDueDate}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <SuggestionActionRenderer
          id={suggestion.id}
          action={suggestion.activities || ''}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <SuggestionCommentRenderer
          id={suggestion.id}
          comments={suggestion.responseComment}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell style={{ minWidth: 100 }}>
        <SuggestionIntegrationStatusRenderer
          id={suggestion.id}
          integrationStatus={suggestion.integrationStatus}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell style={{ minWidth: 50 }}>
        <IconButton onClick={() => onDelete(suggestion.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
