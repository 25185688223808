import { gql } from '@apollo/client'

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: Long!) {
    messages {
      messages {
        deleteMessage(id: $id)
      }
    }
  }
`

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $dateFrom: String!
    $dateTo: String!
    $sendNotifications: Boolean!
    $title: String!
    $description: String
    $notificationDescription: String
  ) {
    messages {
      messages {
        addMessage(
          input: {
            dateFrom: $dateFrom
            dateTo: $dateTo
            sendNotifications: $sendNotifications
            isService: false
            details: [
              {
                languageId: 1
                title: $title
                description: $description
                notificationDescription: $notificationDescription
              }
            ]
            objects: [0]
          }
        ) {
          id
          dateFrom
          dateTo
          sendNotifications
          details {
            languageId
            title
            description
            notificationDescription
          }
        }
      }
    }
  }
`

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: Long!
    $dateFrom: String!
    $dateTo: String!
    $sendNotifications: Boolean!
    $title: String!
    $description: String
    $notificationDescription: String
  ) {
    messages {
      messages {
        updateMessage(
          id: $id
          input: {
            dateFrom: $dateFrom
            dateTo: $dateTo
            sendNotifications: $sendNotifications
            isService: false
            details: [
              {
                languageId: 1
                title: $title
                description: $description
                notificationDescription: $notificationDescription
              }
            ]
            objects: [0]
          }
        ) {
          id
          dateFrom
          dateTo
          sendNotifications
          details {
            languageId
            title
            description
            notificationDescription
          }
        }
      }
    }
  }
`

export const DELETE_SUGGESTION = gql`
  mutation DeleteSuggestion($id: Long!) {
    incidents {
      delete(id: $id)
    }
  }
`

export const UPDATE_SUGGESTION = gql`
  mutation UpdateSuggestion($id: Long!, $input: UpdateIncidentInput!) {
    incidents {
      update(id: $id, input: $input) {
        id
        userId
        title
        description
        lat
        lng
        responseStatus
        contactInfo
        address
        creationDate
        status
        responseComment
        incidentType
        attachmentIds
        plannedDueDate
        integrationStatus
        activities
      }
    }
  }
`

export const DELETE_UPLOAD = gql`
  mutation DeleteUpload($id: Long!) {
    attachments {
      deleteUpload(id: $id)
    }
  }
`

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: Long!) {
    attachments {
      deleteAttachment(id: $id)
    }
  }
`
/**
  type AttachmentInput {
    objectId: Long!
    module: String!
    uploadId: Long!
  }
*/
export const ADD_ATTACHMENT = gql`
  mutation AddAttachment($input: AttachmentInput!) {
    attachments {
      addAttachment(input: $input) {
        id
        upload {
          id
          name
          dateAdded
          mimeType
          size
        }
        objectId
        module
      }
    }
  }
`
