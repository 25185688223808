import React from 'react'
import {
  createTheme,
  ThemeProvider as Provider,
} from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#003057',
    },
    secondary: {
      main: '#4646DB',
      light: '#ECECFB',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '85%',
        },
      },
    },
  },
})

export default function ThemeProvider({ children }) {
  return <Provider theme={theme}>{children}</Provider>
}
