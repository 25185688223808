import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { useConfig } from '@mcity/liepaja-core/src/components/providers/LiepajaConfigProvider'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { GET_ATTACHMENTS_BY_IDS } from '../../../apollo/queries'
import { useQuery } from '@apollo/client'
import { usePasswordAuth } from '../../providers/PasswordAuthProvider'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles({
  carousel: {
    '& ul': {
      padding: 0,
    },
  },
  iconWrapper: {
    position: 'absolute',
    top: 5,
    right: 30,
    cursor: 'pointer',
    lineHeight: '0',
    backgroundColor: 'rgba(255,255,255,0.7)',
    borderRadius: '3px',
  },
  icon: {
    width: 32,
    height: 32,
    color: 'rgba(0,0,0,0.7)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  imageWrapper: {
    position: 'relative',
  },
})

export default function PicturesDialog({ attachmentsIds, onClose }) {
  const classes = useStyles()
  const config = useConfig()

  const { token } = usePasswordAuth()

  const [images, setImages] = React.useState([])

  const { data } = useQuery(GET_ATTACHMENTS_BY_IDS, {
    fetchPolicy: 'network-only',
    variables: {
      ids: attachmentsIds,
    },
    skip: attachmentsIds.length === 0,
  })

  const fetchImages = async attachments => {
    return Promise.all(
      attachments.map(async att => {
        const data = await fetch(`${config.downloadUrl}?file_id=${att.id}`, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        const blob = await data.blob()
        return URL.createObjectURL(blob)
      }),
    )
  }

  React.useEffect(() => {
    setImages([])
    if (data?.attachments?.attachments.length > 0) {
      fetchImages(data?.attachments?.attachments).then(images => {
        setImages(images)
      })
    }
  }, [data, token])

  const downloadImage = async src => {
    const link = document.createElement('a')
    link.href = src
    link.download = 'suggestion'
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <Dialog open onClose={onClose}>
      {images.length > 0 ? (
        <Carousel
          className={classes.carousel}
          dynamicHeight
          showStatus={false}
          showThumbs={false}
        >
          {images.map((src, i) => (
            <div key={i} className={classes.imageWrapper}>
              <Box
                onClick={() => downloadImage(src)}
                className={classes.iconWrapper}
              >
                <GetAppSharpIcon className={classes.icon} />
              </Box>
              <img src={src} alt="suggestion" />
            </div>
          ))}
        </Carousel>
      ) : (
        <CircularProgress />
      )}
    </Dialog>
  )
}
