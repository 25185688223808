import React from 'react'
import HistoryBase from './HistoryBase'
import { LIST_SUGGESTION_HISTORY } from '../../../apollo/queries'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'

const keyLabels = {
  title: 'Virsraksts',
  description: 'Apraksts',
  status: 'Status',
  responseStatus: 'Atbildes status',
  // contactInfo: 'Kontaktinformācija',
  address: 'Adrese',
  creationDate: 'Izveidošanas datums',
  plannedDueDate: 'Izpildīšanas termiņš',
  userId: 'Lietotāja ID',
  responseComment: 'Atbilde',
  attachmentIds: 'Pielikumu ID',
  integrationStatus: 'Integrācijas status',
  incidentType: 'Tips',
  lat: 'GPS (lat)',
  lng: 'GPS (lng)',
}

const keyRenderers = {
  plannedDueDate: value =>
    value ? format(new Date(Number(value)), 'P', { locale: lv }) : '',
  creationDate: value =>
    value ? format(new Date(Number(value)), 'P', { locale: lv }) : '',
}

export default function SuggestionHistory() {
  return (
    <HistoryBase
      query={LIST_SUGGESTION_HISTORY}
      queryPath="incidents"
      keyLabels={keyLabels}
      keyRenderers={keyRenderers}
      title="Ierosinājumu vēsture"
      showOnlyDefinedLabels
    />
  )
}
