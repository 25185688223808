import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { usePasswordAuth } from '../providers/PasswordAuthProvider'

const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  signIn: {
    margin: theme.spacing(3, 0, 2),
    padding: '10px 0',
  },
}))

export default function LoginForm({ onSuccess }) {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  const { login } = usePasswordAuth()

  const onLoginClick = () => {
    login(username, password)
      .then(user => {
        if (onSuccess) {
          onSuccess(user)
        } else {
          history.replace('/')
        }
      })
      .catch(err => {
        setError(err.message)
      })
  }

  const onEnterClick = ev => {
    if (ev.key === 'Enter') onLoginClick()
  }

  return (
    <form className={classes.formWrapper} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Lietotājvārds"
        value={username}
        onChange={({ target }) => setUsername(target.value)}
        onKeyPress={onEnterClick}
        inputProps={{
          autoCapitalize: 'none',
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Parole"
        type="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
        onKeyPress={onEnterClick}
      />
      <Typography color="error" variant="body2">
        {error}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.signIn}
        onClick={onLoginClick}
      >
        Ieiet
      </Button>
    </form>
  )
}
