import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider as Provider,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAppState } from '@mcity/liepaja-core/src/components/providers/AppStateProvider'
import { useConfig } from '@mcity/liepaja-core/src/components/providers/LiepajaConfigProvider'
import React from 'react'
import { CREDENTIALS_USER_TOKEN } from './PasswordAuthProvider'

export default function ApolloProvider({ children }) {
  const config = useConfig()
  const { setError } = useAppState()

  const cache = new InMemoryCache()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const errorIdList = []

      for (const error of graphQLErrors) {
        if (error.extensions && error.extensions.ID) {
          errorIdList.push(error.extensions.ID)
        }
      }

      setError({
        errorIdList,
      })
    }

    if (networkError) {
      setError()
    }
  })

  const httpLink = createHttpLink({
    uri: config.graphqlUri,
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(CREDENTIALS_USER_TOKEN)
    return {
      headers: {
        ...headers,
        authorization: token ? `Token ${token}` : '',
      },
    }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, authLink, httpLink]),
  })

  return client ? <Provider client={client}>{children}</Provider> : <></>
}
