import { useState } from 'react'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'

const useRemoveAttachments = () => {
  const REMOVE_ATTACHMENT = gql`
    mutation removeAttachment($id: Long!) {
      attachments {
        deleteAttachment(id: $id)
      }
    }
  `
  const client = useApolloClient()

  const [loading, setLoading] = useState(0)
  const [error, setError] = useState()
  const [started, setStarted] = useState(false)

  const execute = (fileIds = []) => {
    setError()
    fileIds.map(fid => {
      setLoading(loading => loading + 1)
      setStarted(true)
      client
        .mutate({
          variables: { id: fid },
          mutation: REMOVE_ATTACHMENT,
        })
        .then(response => {
          setLoading(loading => loading - 1)
        })
        .catch(e => {
          setLoading(loading => loading - 1)
          setError(e)
        })
    })
    setStarted(true)
  }

  return { loading, error, started, execute }
}

export default useRemoveAttachments
