import React, { useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import SaveOrCancelDialog from './SaveOrCancelDialog'
import { useMutation } from '@apollo/client'
import { CHANGE_USER_PASSWORD } from '@mcity/core/lib/gql/mutations'

export default function ChangeUsersPasswordDialog({ setOpen, username }) {
  const [changePassword, { loading }] = useMutation(CHANGE_USER_PASSWORD)
  const [complete, setComplete] = useState(false)
  const [alert, setAlert] = useState(null)
  const [newPassword, setNewPassword] = useState('')
  const [errors, setErrors] = useState({})

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }

  const validateForm = () => {
    const errors = {}
    if (newPassword.length < 8) {
      errors.password = 'Parole nevar būt īsāka par 8 simboliem'
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onDialogProceed = () => {
    if (validateForm()) {
      changePassword({
        variables: {
          username,
          newPassword,
        },
      })
        .then(({ data }) => {
          if (data?.passwordAuth?.changePassword) {
            onSuccess({ message: `Parole nomainīta` })
            setComplete(true)
          } else {
            onError({ message: 'Neizdevās nomainīt paroli' })
          }
        })
        .catch(() => {
          onError({ message: 'Pieprasījuma apstrādes kļūda' })
        })
    }
  }

  return (
    <SaveOrCancelDialog
      title={`Paroles maiņa lietotājam ${username}`}
      onProceed={onDialogProceed}
      setOpen={setOpen}
      loading={loading}
      complete={complete}
      alert={alert}
      confirmButtonName="Saglabāt"
    >
      <Box width={300} mb={1}>
        <TextField
          fullWidth
          label="Jaunā parole"
          type="text"
          size="small"
          variant="outlined"
          value={newPassword}
          error={errors?.password?.length > 0}
          helperText={errors.password}
          onChange={event => setNewPassword(event.target.value)}
        />
      </Box>
    </SaveOrCancelDialog>
  )
}
