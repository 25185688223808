import React, { useState } from 'react'
import { Checkbox, Box, TextField } from '@material-ui/core'
import SaveOrCancelDialog from './SaveOrCancelDialog'
import {
  IS_ACTIVE,
  IS_AUDIT_ADMIN,
  IS_NOTIFICATION_ADMIN,
  IS_SUGGESTION_ADMIN,
  IS_USERMANAGEMENT_ADMIN,
} from '../providers/LiepajaAdminAuthProvider'

export default function CreateUserDialog({
  title,
  setOpen,
  onProceed,
  alert,
  loading,
  complete,
}) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [adminRights, setAdminRights] = useState([
    { key: IS_AUDIT_ADMIN, value: false, label: 'Auditu' },
    { key: IS_NOTIFICATION_ADMIN, value: false, label: 'Paziņojumu' },
    { key: IS_SUGGESTION_ADMIN, value: false, label: 'Ierosinājumu' },
    { key: IS_USERMANAGEMENT_ADMIN, value: false, label: 'Lietotāju' },
  ])

  const [errors, setErrors] = useState({})

  const validateForm = () => {
    const errors = {}
    if (username.length < 1) {
      errors.username = 'Šīs lauks ir obligāts'
    }
    if (password.length < 8) {
      errors.password = 'Parole nevar būt īsāka par 8 simboliem'
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onDialogProceed = () => {
    if (validateForm()) {
      onProceed({
        username,
        password,
        params: [
          { key: IS_ACTIVE, value: 'true', isEditable: true },
          ...adminRights.map(({ key, value }) => {
            return {
              key,
              value: value ? 'true' : 'false',
              isEditable: true,
            }
          }),
        ],
      })
    }
  }

  return (
    <SaveOrCancelDialog
      title={title}
      onProceed={onDialogProceed}
      setOpen={setOpen}
      complete={complete}
      alert={alert}
      loading={loading}
      confirmButtonName="Izveidot"
    >
      <Box width={300} mb={1}>
        <TextField
          fullWidth
          label="Lietotājvārds"
          type="username"
          size="small"
          variant="outlined"
          value={username}
          error={errors?.username?.length > 0}
          helperText={errors.username}
          onChange={event => setUsername(event.target.value)}
        />
      </Box>
      <Box width={300} mb={1}>
        <TextField
          fullWidth
          label="Parole"
          type="text"
          size="small"
          variant="outlined"
          value={password}
          error={errors?.password?.length > 0}
          helperText={errors.password}
          onChange={event => setPassword(event.target.value)}
        />
      </Box>
      {adminRights.map((item, index) => {
        return (
          <Box pl={1} key={index}>
            <Checkbox
              checked={item.value}
              onChange={() => {
                const updatedAdminRights = [...adminRights]
                updatedAdminRights[index].value = !updatedAdminRights[index]
                  .value
                setAdminRights(updatedAdminRights)
              }}
            />
            <span>{`${item.label} administrēšanas tiesības`}</span>
          </Box>
        )
      })}
    </SaveOrCancelDialog>
  )
}
