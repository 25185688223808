import React from 'react'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextFieldEditorRenderer from '@mcity/core/lib/components/elements/TextFieldEditorRenderer'
import { UPDATE_SUGGESTION } from '../../../apollo/mutations'

export default function SuggestionCommentRenderer({
  id,
  comments,
  onError,
  onSuccess,
}) {
  const [textValue, setTextValue] = React.useState(comments)
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = value => {
    updateSuggestion({
      variables: {
        id,
        input: {
          responseComment: value,
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          setTextValue(data.incidents.update.responseComment)
          onSuccess({ message: `Komentārs nomainīts` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return <TextFieldEditorRenderer value={textValue} onChange={onChange} />
}
