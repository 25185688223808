import React, { useState } from 'react'
import useExportXlsx from '../useExportXlsx'
import { statusLabels } from './SuggestionStatusRenderer'
import { typesLabels } from './SuggestionTypeRenderer'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'
import { integrationStatusLabels } from './SuggestionIntegrationStatusRenderer'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { useLazyQuery } from '@apollo/client'
import { GET_SUGGESTIONS } from '../../../apollo/queries'
import { useSnackBar } from '@mcity/core/lib/components/providers/SnackBarProvider'
import { getFilter } from '../../../hooks/useFetchAndReshapeSuggestions'

export const SuggestionsExportXlsx = ({ filterText }) => {
  const { showSnackBar } = useSnackBar()
  const [loading, setLoading] = useState(false)
  const [exportData, setExportData] = useState(null)

  const [fetchDataForExport] = useLazyQuery(GET_SUGGESTIONS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 10000,
      skip: 0,
      orderBy: 'id',
      orderAscending: true,
      filter: getFilter(filterText),
    },
    onError: () => {
      setLoading(false)
      setExportData(null)
      showSnackBar({
        severity: 'error',
        text: 'Kļūda sagatavojot datus eksportam',
      })
    },
    onCompleted: res => {
      const data = res?.incidents?.incidents.map(val => [
        statusLabels[val.status],
        typesLabels[val.incidentType],
        format(new Date(val.creationDate), 'P p', { locale: lv }),
        val.title,
        val.description,
        val.address,
        // val.userId,
        val.plannedDueDate
          ? format(new Date(val.plannedDueDate), 'P', { locale: lv })
          : '',
        val.activities || '',
        val.responseComment,
        integrationStatusLabels[val.integrationStatus],
      ])
      setExportData([
        [
          'Statuss',
          'Tips',
          'Izveidošanas datums',
          'Virsraksts',
          'Apraksts',
          'Adrese',
          'Izpildīšanas termiņš',
          'Darbības',
          'Atbilde',
          'Integrācijas status',
        ],
        ...data,
      ])
    },
  })

  const onExportCompleted = () => {
    setLoading(false)
    setExportData(null)
  }

  useExportXlsx(
    exportData,
    onExportCompleted,
    'ierosinajumi',
    'Ierosinajumi',
    'ierosinajumi',
  )

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && (
        <IconButton
          color="primary"
          style={{ padding: 10 }}
          aria-label="export"
          title="Eksportēt XLSX"
          onClick={() => {
            setLoading(true)
            fetchDataForExport()
          }}
        >
          <SystemUpdateAltIcon />
        </IconButton>
      )}
    </>
  )
}
