import { useState } from 'react'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'

import { usePasswordAuth } from '../../providers/PasswordAuthProvider'
import { useConfig } from '@mcity/liepaja-core/src/components/providers/LiepajaConfigProvider'

const useAddAttachments = () => {
  const auth = usePasswordAuth()
  const config = useConfig()
  const client = useApolloClient()
  const ADD_ATTACHMENT = gql`
    mutation addAttachment(
      $objectId: Long!
      $module: String!
      $uploadId: Long!
    ) {
      attachments {
        addAttachment(
          input: { objectId: $objectId, module: $module, uploadId: $uploadId }
        ) {
          id
          upload {
            id
            name
            dateAdded
            mimeType
            size
          }
          objectId
          module
        }
      }
    }
  `

  const [loading, setLoading] = useState(0)
  const [error, setError] = useState()
  const [started, setStarted] = useState(false)

  /**
   *
   * @param {*} messageId
   * @param {*} filesData in form of {fileType: [files, ...], ...}
   */
  const execute = (objectId, moduleName, filesData = {}) => {
    setError()
    setLoading(loading => loading + 1)
    Object.keys(filesData).map(fileType => {
      const files = filesData[fileType]
      files.map(fileData => {
        if (fileData && typeof fileData.name !== 'undefined') {
          setLoading(loading => loading + 1)

          const formData = new FormData()
          formData.append('file_type', fileType)
          formData.append('file', fileData)

          fetch(`${config.uploadUrl}`, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `Token ${auth.token}`,
            },
          })
            .then(resp => resp.json())
            .catch(e => {})
            .then(data => {
              client
                .mutate({
                  variables: {
                    objectId: objectId,
                    module: moduleName,
                    uploadId: data,
                  },
                  mutation: ADD_ATTACHMENT,
                })
                .then(response => {
                  if (response) {
                    setLoading(loading => loading - 1)
                  }
                })
                .catch(e => {
                  setError(e)
                  setLoading(loading => loading - 1)
                })
            })
            .catch(e => {
              setError(e)
              setLoading(loading => loading - 1)
            })
        }
      })
      setStarted(true)
    })
    setLoading(loading => loading - 1)
  }

  return { loading, error, started, execute }
}
export default useAddAttachments
