import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import SuggestionsMap from './SuggestionsMap'
import { useModal } from 'react-modal-hook'
import { SuggestionDeleteConfirmDialog } from './SuggestionDeleteConfirmDialog'
import {useQuery} from "@apollo/client";
import {GET_SUGGESTIONS_SHORT} from "../../../apollo/queries";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "@mcity/core/lib/components/elements/Error";

const INCIDENT_FILTER_MAP = {
  key: 'status',
  value: 'DONE',
  approximate: false,
  flip: false,
  or: {
    key: 'status',
    value: 'NEW',
    approximate: false,
    flip: false,
    or: {
      key: 'status',
      value: 'STARTED',
      approximate: false,
      flip: false,
      or: {
        key: 'status',
        value: 'ACCEPTED',
        approximate: false,
        flip: false,
      },
    },
  },
}

const useStyles = makeStyles(() => ({
  closeIconContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 500,
  },
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  content: {
    width: '80vw',
    height: '80vh',
    padding: '16px',
    overflow: 'hidden',
  },
}))

export default function SuggestionsMapDialog({
  highlightedSuggestion,
  setOpen,
  refetchTableData,
}) {
  const classes = useStyles()
  const [selectedSuggestionIdToDelete, setSelectedSuggestionIdToDelete] =
    useState(null)

  const {data, loading, error, refetch: refetchMapData} = useQuery(GET_SUGGESTIONS_SHORT, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 1000,
      skip: 0,
      filter: INCIDENT_FILTER_MAP,
    },
  })

  const onClose = () => {
    setOpen(false)
  }

  const [showConfirmDialog, hideConfirmDialog] = useModal(() => {
    const hideDialog = () => {
      hideConfirmDialog()
    }
    return selectedSuggestionIdToDelete ? (
      <SuggestionDeleteConfirmDialog
        hideDialog={hideDialog}
        refetch={() => {
          refetchTableData();
        }}
        selectedSuggestionIdToDelete={selectedSuggestionIdToDelete}
        closeModal={onClose}
      />
    ) : null
  }, [selectedSuggestionIdToDelete])

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <Box
        className={classes.closeIconContainer}
        display="flex"
        justifyContent="flex-end"
      >
        <CancelIcon
          className={classes.closeIcon}
          color="primary"
          onClick={onClose}
        />
      </Box>
      <DialogContent className={classes.content}>
        {loading && <CircularProgress />}
        {error && <Error>Kļūda</Error>}
        {!loading && !error && <SuggestionsMap
          suggestions={data.incidents.incidents}
          onDelete={suggestionId => {
            setSelectedSuggestionIdToDelete(suggestionId)
            showConfirmDialog()
          }}
          refetch={() => {
            refetchMapData()
            refetchTableData()
          }}
          highlightedSuggestion={highlightedSuggestion}
          zoom={16}
        />}
      </DialogContent>
    </Dialog>
  )
}
