import React, { useState, useEffect } from 'react'
import L from 'leaflet'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { statusLabels } from './SuggestionStatusRenderer'
import Paper from '@material-ui/core/Paper'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import flagGreyImage from './flags/flag-grey.png'
import flagBlueImage from './flags/flag-blue.png'
import flagGreenImage from './flags/flag-green.png'
import flagYellowImage from './flags/flag-yellow.png'
import flagRedImage from './flags/flag-red.png'
import InfoDialog from '../InfoDialog'
import SuggestionMoreInfo from './SuggestionMoreInfo'
import {SuggestionsMapEditSuggestionDialog} from "./SuggestionsMapEditSuggestionDialog";

const ICON_DIM = 96
const ICON_ANCHOR_X = 20
const ICON_ANCHOR_Y = 86
const ICON_SCALE = 0.4

const ICON_SIZE = [ICON_DIM * ICON_SCALE, ICON_DIM * ICON_SCALE] // size of the icon
const ICON_ANCHOR = [ICON_ANCHOR_X * ICON_SCALE, ICON_ANCHOR_Y * ICON_SCALE] // point of the icon which will correspond to marker's location
const POPUP_ANCHOR = [ICON_ANCHOR_X * ICON_SCALE, -ICON_ANCHOR_Y * ICON_SCALE] // point from which the popup should open relative to the iconAnchor

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  popup: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  map: {
    width: '100%',
    height: '100%',
  },
}))

const createFlag = image =>
  new L.Icon({
    iconUrl: image,
    iconRetinaUrl: image,
    iconSize: ICON_SIZE,
    iconAnchor: ICON_ANCHOR,
    popupAnchor: POPUP_ANCHOR,
  })
const flagGrey = createFlag(flagGreyImage)
const flagBlue = createFlag(flagBlueImage)
const flagGreen = createFlag(flagGreenImage)
const flagYellow = createFlag(flagYellowImage)
const flagRed = createFlag(flagRedImage)

export const statusIcons = {
  NEW: flagGrey,
  STARTED: flagBlue,
  ACCEPTED: flagYellow,
  DONE: flagGreen,
  CLOSED: flagRed,
}

export default function SuggestionsMap({
  suggestions,
  onDelete,
  highlightedSuggestion,
  zoom = 13,
  refetch,
}) {
  const { location } = useHistory()
  const query = new URLSearchParams(location.search)
  const hasCoordinates = query.has('lat') && query.has('lng')
  let center = hasCoordinates
    ? [query.get('lat'), query.get('lng')]
    : [56.5047, 21.0108]
  if (highlightedSuggestion) {
    center = [
      highlightedSuggestion?.lat || 56.5047,
      highlightedSuggestion?.lng || 21.0108,
    ]
  }

  const classes = useStyles()
  const [editSuggestion, setEditSuggestion] = React.useState(null)
  const [alert, setAlert] = useState(null)
  const [moreInfo, setMoreInfo] = React.useState(null)

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }

  const onSuccess = ({ message }) => {
    if (typeof refetch === 'function') {
      refetch()
    }
    setAlert({ severity: 'success', message })
  }

  const onCloseAlert = () => {
    setAlert(null)
  }

  const onDialogClose = () => {
    setEditSuggestion(null)
  }

  const onOpenDescription = info => {
    setMoreInfo(info)
  }

  return (
    <Paper className={classes.root}>
      {moreInfo && (
        <InfoDialog title="Papildus Informācija" setOpen={setMoreInfo}>
          <SuggestionMoreInfo {...moreInfo} />
        </InfoDialog>
      )}
      {editSuggestion && <SuggestionsMapEditSuggestionDialog
        onError={onError}
        onSuccess={onSuccess}
        id={editSuggestion.id}
        onDialogClose={onDialogClose}
        onDelete={onDelete}
        onOpenDescription={onOpenDescription}
      />}
      <Snackbar
        open={alert !== null}
        autoHideDuration={6000}
        onClose={onCloseAlert}
      >
        <MuiAlert
          onClose={onCloseAlert}
          severity={alert ? alert.severity : 'info'}
        >
          {alert ? alert.message : ''}
        </MuiAlert>
      </Snackbar>
      <Map
        center={center}
        zoom={hasCoordinates ? 18 : zoom}
        className={classes.map}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {highlightedSuggestion && (
          <Marker position={center}>
            <Popup>
              <div className={classes.popup}>
                <Typography variant="h6">
                  {statusLabels[highlightedSuggestion.status]}
                </Typography>
                <Typography variant="body1" component="p">
                  {highlightedSuggestion.description}
                </Typography>
                <Button
                  color="primary"
                  style={{ alignSelf: 'flex-end' }}
                  startIcon={<EditIcon />}
                  onClick={() => setEditSuggestion(highlightedSuggestion)}
                >
                  LABOT
                </Button>
              </div>
            </Popup>
          </Marker>
        )}
        <MarkerClusterGroup>
          {suggestions
            .filter(s => s.id !== highlightedSuggestion?.id)
            .map((s, index) => (
              <Marker
                position={[s.lat, s.lng]}
                icon={statusIcons[s.status]}
                key={index}
              >
                <Popup>
                  <div className={classes.popup}>
                    <Typography variant="h6">
                      {statusLabels[s.status]}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {s.description}
                    </Typography>
                    <Button
                      color="primary"
                      style={{ alignSelf: 'flex-end' }}
                      startIcon={<EditIcon />}
                      onClick={() => setEditSuggestion(s)}
                    >
                      LABOT
                    </Button>
                  </div>
                </Popup>
              </Marker>
            ))}
        </MarkerClusterGroup>
      </Map>
    </Paper>
  )
}
