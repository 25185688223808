import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import NotificationEditor from './NotificationEditor'
import {
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '../../../apollo/mutations'
import { GET_NOTIFICATION } from '../../../apollo/queries'
import Loader from '@mcity/core/lib/components/elements/Loader'

export default function NotificationCreateDialog({
  setOpen,
  onComplete,
  notificationId,
}) {
  const isEditMode = Boolean(notificationId)
  const [createNotification, { loading: loadingCreate }] = useMutation(
    CREATE_NOTIFICATION,
  )
  const [updateNotification, { loading: loadingEdit }] = useMutation(
    UPDATE_NOTIFICATION,
  )
  const [notification, setNotification] = useState()
  const [alert, setAlert] = useState(null)
  const [complete, setComplete] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [executeUpload, setExecuteUpload] = useState(false)

  // TODO: Move this to the parent
  const {
    loading: loadingNotificationById,
    data: notificationToEdit,
  } = useQuery(GET_NOTIFICATION, {
    variables: {
      id: notificationId,
    },
    skip: !isEditMode,
  })

  useEffect(() => {
    if (notificationToEdit?.messages?.messages?.message) {
      setNotification(notificationToEdit?.messages?.messages?.message)
    }
  }, [notificationToEdit])

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }
  const onSetNotification = notification => {
    setAlert(null)
    const input = notification
    if (!isEditMode)
      createNotification({ variables: { ...input } })
        .then(({ data }) => {
          if (data?.messages?.messages?.addMessage?.id) {
            setSuccessMsg(`Paziņojums izveidots`)
            setNotification(data.messages.messages.addMessage)
            setExecuteUpload(true)
          }
        })
        .catch(() => {
          onError({ message: 'Pieprasījuma apstrādes kļūda' })
        })
    else
      updateNotification({ variables: { id: notificationId, ...input } })
        .then(({ data }) => {
          if (data?.messages?.messages?.updateMessage?.id) {
            setSuccessMsg(`Paziņojums saglabāts`)
            setNotification(data.messages.messages.updateMessage)
            setExecuteUpload(true)
          }
        })
        .catch(() => {
          onError({ message: 'Pieprasījuma apstrādes kļūda' })
        })
  }

  const onUploadFinished = () => {
    setExecuteUpload(false)
    onSuccess({ message: successMsg })
    setComplete(true)
    onComplete()
  }

  return (
    <>
      <Loader
        loading={
          loadingNotificationById ||
          loadingCreate ||
          loadingEdit ||
          executeUpload
        }
      />
      <NotificationEditor
        setNotification={onSetNotification}
        setOpen={setOpen}
        alert={alert}
        complete={complete}
        notification={notification}
        executeUpload={executeUpload}
        onUploadFinished={() => onUploadFinished()}
      />
    </>
  )
}
