import { gql } from '@apollo/client'

export const PASSWORD_AUTH = gql`
  query PasswordAuth($username: String!, $password: String!) {
    passwordAuth {
      login(input: { username: $username, password: $password }) {
        userId
        token
        params {
          key
          value
        }
      }
    }
  }
`

export const LIST_NOTIFICATIONS = gql`
  query ListNotifications(
    $limit: Int!
    $skip: Int!
    $orderBy: String!
    $orderAscending: Boolean!
  ) {
    messages {
      messages {
        messages(
          lookup: {
            limit: $limit
            skip: $skip
            orderBy: $orderBy
            orderAscending: $orderAscending
          }
          periodType: ALL
        ) {
          id
          dateFrom
          dateTo
          sendNotifications
          dateAdded
          details {
            languageId
            title
            description
            notificationDescription
          }
        }
      }
    }
  }
`

export const GET_NOTIFICATIONS_COUNT = gql`
query getNotificationsCount {
  messages {
    messages {
      messagesTotalCount(
        lookup: {
          limit: 10
          skip: 0
          orderBy: "dateFrom"
          orderAscending: true
        }
      )
    }
  }
}
`

export const GET_NOTIFICATION = gql`
  query GetNotification($id: Long!) {
    messages {
      messages {
        message(id: $id) {
          id
          dateFrom
          dateTo
          sendNotifications
          details {
            languageId
            title
            description
            notificationDescription
          }
        }
      }
    }
  }
`

export const GET_INCIDENTS = gql`
  query GetSuggestions($limit: Int!, $skip: Int!, $filter: ListFilter) {
    incidents {
      incidents(
        lookup: {
          limit: $limit
          skip: $skip
          orderAscending: false
          orderBy: "id"
          filter: $filter
        }
      ) {
        id
        userId
        title
        description
        lat
        lng
        responseStatus
        contactInfo
        address
        creationDate
        status
        responseComment
        incidentType
        attachmentIds
        plannedDueDate
        integrationStatus
      }
    }
  }
`

export const GET_SUGGESTIONS = gql`
query GetSuggestions(
  $limit: Int!
  $skip: Int!
  $filter: ListFilter
  $orderBy: String!
  $orderAscending: Boolean!
) {
  incidents {
    incidents(
      lookup: {
        limit: $limit
        skip: $skip
        orderAscending: $orderAscending
        orderBy: $orderBy
        filter: $filter
      }
    ) {
      id
      userId
      title
      description
      lat
      lng
      responseStatus
      contactInfo
      address
      creationDate
      status
      responseComment
      incidentType
      attachmentIds
      plannedDueDate
      integrationStatus
      activities
    }
  }
}
`

export const GET_SUGGESTIONS_COUNT = gql`
query getSuggestionsCount($filter: ListFilter) {
  incidents {
    incidentsTotalCount(
      lookup: {
        limit: 10
        skip: 0
        orderAscending: false
        orderBy: "id"
        filter: $filter
      }
    )
  }
}
`

export const GET_SUGGESTIONS_SHORT = gql`
query GetSuggestionsShort($limit: Int!, $skip: Int!, $filter: ListFilter) {
  incidents {
    incidents(
      lookup: {
        limit: $limit
        skip: $skip
        orderAscending: true
        orderBy: "id"
        filter: $filter
      }
    ) {
      id
      userId
      description
      lat
      lng
      status
    }
  }
}
`

export const GET_ONE_SUGGESTION = gql`
  query GetSuggestion($id: Long!) {
    incidents {
      incident(id: $id) {
        id
        userId
        title
        description
        lat
        lng
        responseStatus
        responseDate
        contactInfo
        address
        creationDate
        status
        responseComment
        incidentType
        attachmentIds
        plannedDueDate
        integrationStatus
        activities
      }
    }
  }
`
export const LIST_SUGGESTION_HISTORY = gql`
  query ListEventHistory(
    $limit: Int!
    $skip: Int!
    $orderBy: String!
    $orderAscending: Boolean!
    $filter: ListFilter
  ) {
    incidents {
      history(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: $orderBy
          orderAscending: $orderAscending
          filter: $filter
        }
      ) {
        list {
          kind
          audit {
            userId
            ipAddress
            actionTime
          }
          previousEntityId
          currentEntityId
          baseEntityId
          differences {
            key
            previousValue
            currentValue
          }
        }
        count
        totalCount
      }
    }
  }
`

export const LIST_NOTIFICATIONS_HISTORY = gql`
  query ListEventHistory(
    $limit: Int!
    $skip: Int!
    $orderBy: String!
    $orderAscending: Boolean!
    $filter: ListFilter
  ) {
    messages {
      messages {
        history(
          lookup: {
            limit: $limit
            skip: $skip
            orderBy: $orderBy
            orderAscending: $orderAscending
            filter: $filter
          }
        ) {
          list {
            kind
            audit {
              userId
              ipAddress
              actionTime
            }
            previousEntityId
            currentEntityId
            baseEntityId
            differences {
              key
              previousValue
              currentValue
            }
          }
          count
          totalCount
        }
      }
    }
  }
`

/**
type AttachmentSelection {
  afterId: Long
  limit: Int! = 100
  objectId: Long
  module: String
  name: String
  fileType: String
}
*/
export const GET_ATTACHMENTS = gql`
  query GetAttachmant($select: AttachmentSelection!) {
    attachments {
      listAttachments(select: $select) {
        list {
          id
          upload {
            id
            name
            dateAdded
            mimeType
            size
          }
          objectId
          module
        }
        count
      }
    }
  }
`

export const GET_ATTACHMENTS_BY_IDS = gql`
  query GetAttachmant($ids: [Long!]!) {
    attachments {
      attachments(ids: $ids) {
        id
        upload {
          id
          name
          dateAdded
          mimeType
          size
        }
        objectId
        module
      }
    }
  }
`

export const GET_FEEDBACK = gql`
  query GetFeedback($id: String!) {
    feedback {
      responses(
        lookup: {
          filter: {
            key: "messageId"
            value: $id
            approximate: false
            flip: false
          }
          limit: 1000
          skip: 0
          orderBy: "id"
          orderAscending: true
        }
      ) {
        id
        rating
        comment
        messageId
      }
    }
  }
`

export const GET_FEEDBACK_FOR_IDS = gql`
  query GetFeedback($filter: ListFilter) {
    feedback {
      responses(
        lookup: {
          filter: $filter
          limit: 1000
          skip: 0
          orderBy: "id"
          orderAscending: true
        }
      ) {
        id
        rating
        comment
        messageId
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query getUserById($id: Long!){
    authorizationUsers{
      userById(id: $id){
        uniqueId
      }
    }
  }
`