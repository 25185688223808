import React from 'react'

export default function LiepajaLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="24">
            <path d="M21.096 2.073a2.131 2.131 0 01-.771 1.408c-.414.37-.95.574-1.505.575a1.674 1.674 0 01-1.713-1.983 2.303 2.303 0 01.784-1.419 2.188 2.188 0 011.493-.587 1.665 1.665 0 011.334.587c.333.391.472.913.378 1.419zM9.65 18.739c-.033.261-.171.392-.415.392H0L2.447 1.705h3.139L3.541 16.267h6.452l-.343 2.472zM14.796 18.739c-.033.26-.172.391-.415.391h-2.746l1.543-10.99-1.444-.147.318-2.227h4.57l-1.826 12.973zM28.537 12.89c-.049.376-.261.563-.636.562h-6.886l-.083.415a3.742 3.742 0 00-.029.461c0 1.423.791 2.133 2.373 2.133.865 0 2.138-.253 3.818-.759l.294 2.668c-1.877.636-3.37.954-4.478.954-1.681 0-2.978-.473-3.891-1.419-.831-.831-1.246-1.908-1.246-3.231-.001-.27.016-.539.048-.807l.392-2.815a6.375 6.375 0 011.958-3.818 5.748 5.748 0 014.111-1.662c1.436 0 2.57.465 3.402 1.394.783.882 1.174 1.967 1.174 3.256 0 .279-.025.557-.073.831l-.248 1.837zm-2.888-1.763c.015-.194.025-.379.025-.554 0-1.423-.595-2.134-1.786-2.134-1.354 0-2.203.897-2.545 2.692l4.306-.004z"/>
            <path fill="#D02927" d="M82.971 2.073a2.131 2.131 0 01-.771 1.408c-.414.37-.95.574-1.505.575a1.674 1.674 0 01-1.713-1.983 2.303 2.303 0 01.784-1.419 2.188 2.188 0 011.493-.587 1.665 1.665 0 011.334.587c.333.391.473.913.378 1.419z"/>
            <path d="M65.94 2.073a2.131 2.131 0 01-.771 1.408c-.414.37-.95.574-1.505.575a1.674 1.674 0 01-1.713-1.983 2.303 2.303 0 01.784-1.419 2.188 2.188 0 011.493-.587 1.665 1.665 0 011.334.587c.333.391.472.913.378 1.419zM49.094.628l-.401 2.854h8.492l.403-2.854zM51.919 5.804l-.21.512c-.995-.473-1.876-.709-2.642-.709a5.285 5.285 0 00-3.707 1.451 5.845 5.845 0 00-1.867 3.617l-.514 3.623a6.8 6.8 0 00-.048.807c0 1.223.384 2.239 1.151 3.046.767.808 1.787 1.212 3.059 1.212.831 0 1.875-.285 3.132-.856l-.025.661h2.662l1.885-13.363-2.876-.001zm-1.7 10.203a7.108 7.108 0 01-2.47.493c-1.126 0-1.689-.631-1.688-1.892 0-.196.023-.391.069-.582l.427-3.009c.244-1.698 1.033-2.547 2.368-2.547.793.02 1.578.169 2.324.442l-1.03 7.095zM71.592 5.804l-.21.512c-.995-.473-1.876-.709-2.642-.709a5.285 5.285 0 00-3.707 1.451 5.852 5.852 0 00-1.877 3.617l-.514 3.623a6.8 6.8 0 00-.048.807c0 1.223.384 2.239 1.151 3.046.767.808 1.787 1.212 3.059 1.212.831 0 1.875-.285 3.132-.856l-.025.661h2.668l1.885-13.363-2.872-.001zm-1.7 10.203a7.108 7.108 0 01-2.47.493c-1.126 0-1.689-.631-1.688-1.892 0-.196.023-.391.069-.582l.428-3.009c.244-1.698 1.033-2.547 2.368-2.547.79.021 1.571.17 2.313.442l-1.02 7.095zM40.42 6.77c-.756-.799-1.768-1.199-3.037-1.199-.96 0-2.001.285-3.123.856v-.684h-2.642l-2.527 17.965h3.123l.706-5.041c.906.439 1.751.658 2.537.659a5.214 5.214 0 003.688-1.48 5.93 5.93 0 001.872-3.61l.514-3.6c.017-.277.025-.546.025-.807-.001-1.24-.38-2.259-1.136-3.059zm-2.044 4.137l-.422 2.984c-.243 1.714-1.028 2.572-2.354 2.572a7.62 7.62 0 01-2.283-.415l.969-7.126a6.29 6.29 0 012.447-.49c1.125 0 1.688.622 1.688 1.867a4.783 4.783 0 01-.045.608zM56.872 5.804l-.316 2.224 1.437.145-1.522 10.887-.046.331a1.563 1.563 0 01-1.496 1.349c-.422 0-.843-.039-1.257-.119l-.44 3.117a6.605 6.605 0 001.602.197c1.208 0 2.235-.359 3.084-1.077.848-.718 1.356-1.681 1.523-2.889l2.007-14.172-4.576.007z"/>
        </svg>
    );
}