import React, { useEffect, useState, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import FileIcon from '@material-ui/icons/InsertDriveFile'
import ClearIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import { usePasswordAuth } from '../../providers/PasswordAuthProvider'

import Typography from '@material-ui/core/Typography'

import AddFileButton from './AddFileButton'
import useFetchAttachments from '@mcity/core/lib/components/elements/attachments/useFetchAttachments'
import useRemoveAttachments from './useRemoveAttachments'
import useAddAttachments from './useAddAttachments'

import { useConfig } from '@mcity/liepaja-core/src/components/providers/LiepajaConfigProvider'

const useStyles = makeStyles(theme => ({
  fileIcon: {
    width: 18,
    height: 18,
    marginRight: '4px',
  },
  deleteIcon: {
    width: 18,
    height: 18,
    color: '#ff3a33',
    fontWeight: 600,
  },
  deleteBtn: {
    minWidth: 18,
  },
  fileButton: {
    color: '#8c8c8c !important',
  },
  linkText: {
    fontWeight: 500,
    paddingLeft: 0,
    display: 'inline-block',
  },
  newFile: {
    color: '#1ed760',
  },
  hidden: {
    display: 'none',
  },
}))

export default function UploadMultipleFiles({
  id = '',
  title = 'Fails',
  objectId = 0,
  type = 'document', // document for pdf, doc, docx, xls, xlsx; image for jpeg, png; default for all document and image
  moduleName = '',
  isChanged = false,
  setIsChanged = () => {},
  setSaved = () => {},
  executeSave = false,
  setFilesCount = () => {},
  readOnly = false,
  ...props
}) {
  const auth = usePasswordAuth()
  const config = useConfig()
  const classes = useStyles()

  const [uploadFiles, setUploadFiles] = useState([])
  const [deleteFiles, setDeleteFiles] = useState([])

  const [startedUploadFiles, setStartedUploadFiles] = useState(false)
  const [startedDeleteFiles, setStartedDeleteFiles] = useState(false)

  const { data: oldFilesData, refetch } = useFetchAttachments(
    objectId,
    moduleName,
    type,
  )
  const files = useMemo(() => {
    if (oldFilesData && oldFilesData.count > 0) {
      setFilesCount(oldFilesData.count)
      return oldFilesData.list
    } else {
      setFilesCount(0)
      return []
    }
  }, [oldFilesData, setFilesCount])
  // File upload
  const {
    loading: loadingDeleteFiles,
    error: errorDeleteFiles,
    execute: executeDeleteFiles,
  } = useRemoveAttachments()
  const {
    loading: loadingUploadFiles,
    error: errorUploadFiles,
    execute: executeUploadFiles,
  } = useAddAttachments()

  useEffect(() => {
    if (!readOnly && executeSave) {
      // save file mutation
      if (uploadFiles.length > 0 && !startedUploadFiles && objectId > 0) {
        const uploadFilesFormatted = {}
        uploadFilesFormatted[type] = uploadFiles
        executeUploadFiles(objectId, moduleName, uploadFilesFormatted, type)
        setStartedUploadFiles(true)
      }
      if (deleteFiles.length > 0 && !startedDeleteFiles) {
        executeDeleteFiles(deleteFiles)
        setStartedDeleteFiles(true)
      }
    }
  }, [
    executeSave,
    readOnly,
    type,
    uploadFiles,
    objectId,
    moduleName,
    deleteFiles,
    startedDeleteFiles,
    startedUploadFiles,
  ])

  useEffect(() => {
    if (
      !readOnly &&
      executeSave &&
      loadingDeleteFiles === 0 &&
      !errorDeleteFiles &&
      (deleteFiles.length === 0 || startedDeleteFiles) &&
      loadingUploadFiles === 0 &&
      !errorUploadFiles &&
      (uploadFiles.length === 0 || startedUploadFiles)
    ) {
      setSaved(true)
      refetch()
      setUploadFiles([])
      setDeleteFiles([])
      setStartedUploadFiles(false)
      setStartedDeleteFiles(false)
    }
  }, [
    loadingDeleteFiles,
    errorDeleteFiles,
    startedDeleteFiles,
    startedUploadFiles,
    loadingUploadFiles,
    errorUploadFiles,
    deleteFiles,
    uploadFiles,
    executeSave,
    readOnly,
  ])

  useEffect(() => {
    if (!readOnly) {
      if (uploadFiles.length > 0 || deleteFiles.length > 0) {
        if (!isChanged) {
          setIsChanged(true)
        }
      } else if (isChanged) {
        setIsChanged(false)
      }
    }
  }, [uploadFiles, deleteFiles, isChanged, setIsChanged, readOnly])

  const handleDownloadFile = (fileId, filename) => {
    fetch(`${config.downloadUrl}?file_id=${fileId}`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${auth.token}`,
      },
    })
      .then(resp => resp.blob())
      .catch(e => {})
      .then(blob => {
        var a = document.createElement('a')
        a.download = filename
        a.href = URL.createObjectURL(blob)
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }

  const handleDeleteFile = fileId => {
    setDeleteFiles([...deleteFiles, fileId])
  }

  const handleFileUpload = files => {
    setUploadFiles([...uploadFiles, ...files])
  }

  const handleDeleteNewFile = key => {
    const arrayCopy = [...uploadFiles]
    arrayCopy.splice(key, 1)
    setUploadFiles(arrayCopy)
  }

  if (!readOnly || files.length > 0)
    return (
      <>
        <Box pt={1}>
          {!readOnly &&
            uploadFiles.map(
              (file, index) =>
                file && (
                  <Box
                    key={index}
                    style={{ overflow: 'hidden' }}
                    className={classes.newFile}
                  >
                    <Button disabled>
                      <FileIcon className={classes.fileIcon} />
                      <Typography variant="body2" className={classes.linkText}>
                        {file.name}
                      </Typography>
                    </Button>
                    <Button
                      className={classes.deleteBtn}
                      onClick={() => {
                        handleDeleteNewFile(index)
                      }}
                    >
                      <ClearIcon className={classes.deleteIcon} />
                    </Button>
                  </Box>
                ),
            )}
          {files.map(
            (file, index) =>
              file && (
                <Box
                  key={index}
                  id={`file${file.id}`}
                  style={{ overflow: 'hidden' }}
                  className={
                    deleteFiles.includes(file.id) ? classes.hidden : ''
                  }
                >
                  <Button
                    className={classes.fileButton}
                    onClick={() => {
                      handleDownloadFile(file.id, file.upload.name)
                    }}
                  >
                    <FileIcon className={classes.fileIcon} />
                    <Typography variant="body2" className={classes.linkText}>
                      {file.upload.name}
                    </Typography>
                  </Button>
                  {!readOnly && (
                    <Button
                      className={classes.deleteBtn}
                      onClick={() => {
                        handleDeleteFile(file.id)
                      }}
                    >
                      <ClearIcon className={classes.deleteIcon} />
                    </Button>
                  )}
                </Box>
              ),
          )}
          {!readOnly && (
            <AddFileButton onChange={handleFileUpload} fileType={type}>
              Pievienot failu
            </AddFileButton>
          )}
        </Box>
      </>
    )
  else return <></>
}
