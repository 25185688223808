import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UPDATE_SUGGESTION } from '../../../apollo/mutations'
import { MARKED_FOR_UPLOAD } from './SuggestionIntegrationStatusRenderer'

const STATUS_NEW = 'NEW'
const STATUS_STARTED = 'STARTED'
const STATUS_DONE = 'DONE'
const STATUS_CLOSED = 'CLOSED'

export const statusLabels = {
  [STATUS_NEW]: 'Jauns',
  [STATUS_STARTED]: 'Apstrādē',
  [STATUS_DONE]: 'Izpildīts',
  [STATUS_CLOSED]: 'Aizvērts ',
}

export const statusCompareWeights = {
  [STATUS_NEW]: 0,
  [STATUS_STARTED]: 1,
  [STATUS_DONE]: 2,
  [STATUS_CLOSED]: 3,
}

export const STATUSES = [STATUS_NEW, STATUS_STARTED, STATUS_DONE, STATUS_CLOSED]

export default function SuggestionStatusRenderer({
  id,
  status,
  onError,
  onSuccess,
  integrationStatus,
}) {
  const [value, setValue] = useState(status)
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = event => {
    updateSuggestion({
      variables: {
        id,
        input: {
          status: event.target.value,
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          setValue(data.incidents.update.status)
          onSuccess({ message: `Statuss nomainīts` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={integrationStatus === MARKED_FOR_UPLOAD}
    >
      {STATUSES.map(status => (
        <MenuItem key={status} value={status}>
          {statusLabels[status]}
        </MenuItem>
      ))}
    </Select>
  )
}
