import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CreateAdminUserDialog from '../elements/CreateAdminUserDialog'
import {
  IS_ACTIVE,
  IS_AUDIT_ADMIN,
  IS_NOTIFICATION_ADMIN,
  IS_SUGGESTION_ADMIN,
  IS_USERMANAGEMENT_ADMIN,
} from '../providers/LiepajaAdminAuthProvider'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { ChangeUsersInfoButton } from '../elements/ChangeUsersInfoButton'
import ChangeUsersPasswordDialog from '../elements/ChangeUsersPasswordDialog'
import ChangeUsersParamsDialog from '../elements/ChangeUsersParamsDialog'
import { GET_USERS } from '@mcity/core/lib/gql/queries'
import Loader from "../elements/LoaderOverlay";

const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
const CHANGE_PARAMS = 'CHANGE_PARAMS'

const reshapeParam = (allParams, paramKeyName) => {
  return allParams.find(param => param.key === paramKeyName)?.value === 'true'
    ? 'Jā'
    : 'Nē'
}

function reshapeUserData(user) {
  return {
    id: user.id,
    username: user.uniqueId,
    notifications: reshapeParam(user.params, IS_NOTIFICATION_ADMIN),
    suggestions: reshapeParam(user.params, IS_SUGGESTION_ADMIN),
    audits: reshapeParam(user.params, IS_AUDIT_ADMIN),
    user_management: reshapeParam(user.params, IS_USERMANAGEMENT_ADMIN),
    is_active: reshapeParam(user.params, IS_ACTIVE),
  }
}

export const columns = [
  { field: 'id', title: 'Lietotāja ID' },
  { field: 'username', title: 'Lietotājvārds' },
  { field: 'notifications', title: 'Paziņojumi' },
  { field: 'suggestions', title: 'Ierosinājumi' },
  { field: 'audits', title: 'Audits' },
  { field: 'user_management', title: 'Lietotāju pārvaldība' },
  { field: 'is_active', title: 'Aktīvs Lietotājs' },
  { field: 'edit_user', title: '' },
]

const dropwDownItems = [
  { value: CHANGE_PASSWORD, label: 'Mainīt paroli' },
  { value: CHANGE_PARAMS, label: 'Mainīt statusus' },
]

export default function UserManagement() {
  const [createUser, setCreateUser] = React.useState(false)
  const [editUserInfo, setEditUserInfo] = React.useState(null)
  const [rows, setRows] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const [nextWindow, setNextWindow] = useState(null)
  const [openEditUserInfo, setOpenEditUserInfo] = useState(false)
  const { data, refetch, loading } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(() => {
    const users = data?.authorizationUsers?.listUsersByAuthenticator
    if (users) {
      const sortedUsers = [...users]
      sortedUsers.sort((prev, next) => next.id - prev.id)
      setRows(sortedUsers.map(reshapeUserData))
      setTotalCount(sortedUsers.length)
    }
  }, [data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      {loading && <Loader />}
      {createUser && (
        <CreateAdminUserDialog
          setOpen={setCreateUser}
          onComplete={() => refetch()}
        />
      )}
      {nextWindow === CHANGE_PASSWORD && openEditUserInfo && (
        <ChangeUsersPasswordDialog
          username={editUserInfo.username}
          setOpen={setOpenEditUserInfo}
        />
      )}
      {nextWindow === CHANGE_PARAMS && openEditUserInfo && (
        <ChangeUsersParamsDialog
          id={editUserInfo.id}
          username={editUserInfo.username}
          setOpen={setOpenEditUserInfo}
          onComplete={() => refetch()}
        />
      )}

      <Box p={2} display="flex" justifyContent="flex-end">
        <Box>
          <Button
            onClick={() => setCreateUser(true)}
            variant="contained"
            startIcon={<PersonAddIcon />}
            color="primary"
          >
            Pievienot
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                return <TableCell key={index}>{column.title}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell>{row.notifications}</TableCell>
                    <TableCell>{row.suggestions}</TableCell>
                    <TableCell>{row.audits}</TableCell>
                    <TableCell>{row.user_management}</TableCell>
                    <TableCell>{row.is_active}</TableCell>
                    <TableCell>
                      <div onClick={() => setEditUserInfo(row)}>
                        <ChangeUsersInfoButton
                          items={dropwDownItems}
                          setNextWindow={setNextWindow}
                          setOpenNextWindow={setOpenEditUserInfo}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({from, to, count}) => `${from}-${to} no ${count}`}
          labelRowsPerPage="Rindas lapā"
        />
      </TableContainer>
    </>
  )
}
