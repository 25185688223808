import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import SuggestionEdit from './SuggestionEdit'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ImageIcon from '@material-ui/icons/Image'
import PicturesDialog from './PicturesDialog'
import { useModal } from 'react-modal-hook'
import InfoDialog from '../InfoDialog'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import SearchIcon from '@material-ui/icons/Search'
import SuggestionMoreInfo from './SuggestionMoreInfo'
import SuggestionsMapDialog from './SuggestionMapDialog'
import {SuggestionsExportXlsx} from "./SuggestionsExportXlsx";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  alerts: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  description: {
    maxWidth: '1200px',
  },
  small: {
    maxWidth: '200px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchField: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    float: 'right',
  },
  table: {
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
  },
}))

export const suggestionTableColumns = [
  { id: 'id', label: 'ID', sortable: true },
  { id: 'status', label: 'Statuss', sortable: true },
  { id: 'incident_type', label: 'Tips', sortable: true },
  { id: 'creation_date', label: 'Izveidošanas datums', sortable: true },
  { id: 'title', label: 'Virsraksts', sortable: false },
  { id: 'description', label: 'Apraksts', sortable: true },
  { id: 'address', label: 'Adrese', sortable: false },
  { id: 'planned_due_date', label: 'Izpildīšanas termiņš', sortable: true },
  { id: 'actions', label: 'Darbības', sortable: false },
  { id: 'responseComment', label: 'Atbilde', sortable: false },
  { id: 'integration_status', label: 'Integrācijas status', sortable: true },
  { id: 'actions', label: '', sortable: false },
]

export default function SuggestionsTable({
  rows,
  onDelete,
  refetchTableData,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  onSearchClick,
  onOrderByChange,
  orderBy,
  onOrderChange,
  order,
  count,
  filterText,
  loading
}) {
  const classes = useStyles()
  const [alert, setAlert] = useState(null)
  const [dialogImages, setDialogImages] = useState([])
  const [moreInfo, setMoreInfo] = useState(null)
  const [searchInputValue, setSearchInputValue] = useState(filterText)
  const [showMap, setShowMap] = useState(false)
  const [mapInfo, setMapInfo] = useState(null)

  const [showPicturesDialog, hidePicturesDialog] = useModal(
    () =>
      dialogImages.length > 0 && (
        <PicturesDialog
          attachmentsIds={dialogImages}
          onClose={() => {
            hidePicturesDialog()
            setDialogImages([])
          }}
        />
      ),
    [dialogImages],
  )

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }

  const onSuccess = ({ message }) => {
    refetchTableData().then(() => setAlert({severity: 'success', message}))
  }

  const onCloseAlert = () => {
    setAlert(null)
  }

  const openDescription = info => {
    setMoreInfo(info)
  }

  const TableBodyContent = () => {
    return (
      <>
        {rows.map(suggestion => (
            <SuggestionEdit
              key={suggestion.id}
              suggestion={suggestion}
              onDelete={onDelete}
              onSuccess={onSuccess}
              onError={onError}
              openDescription={openDescription}
            >
              <TableCell>
                <IconButton
                  onClick={() => {
                    setShowMap(true)
                    setMapInfo(suggestion)
                  }}
                >
                  <LocationOnIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  disabled={suggestion.attachmentIds.length < 1}
                  onClick={() => {
                    setDialogImages(suggestion.attachmentIds)
                    showPicturesDialog()
                  }}
                >
                  <ImageIcon />
                </IconButton>
              </TableCell>
            </SuggestionEdit>
          ))}
      </>
    )
  }

  return (
    <>
      {moreInfo && (
        <InfoDialog title="Papildus Informācija" setOpen={setMoreInfo}>
          <SuggestionMoreInfo {...moreInfo} />
        </InfoDialog>
      )}
      {showMap && (
        <SuggestionsMapDialog
          setOpen={setShowMap}
          highlightedSuggestion={mapInfo}
          refetchTableData={refetchTableData}
        />
      )}
      <Paper className={classes.root}>
        <Snackbar
          open={alert !== null}
          autoHideDuration={6000}
          onClose={onCloseAlert}
        >
          <MuiAlert
            onClose={onCloseAlert}
            severity={alert ? alert.severity : 'info'}
          >
            {alert ? alert.message : ''}
          </MuiAlert>
        </Snackbar>
        <TableContainer component={Paper} className={classes.table}>
          <Table stickyHeader>
            <colgroup>
              <col style={{ width: '1%' }} />
              <col style={{ width: '1%' }} />
              <col style={{ width: '1%', minWidth: '50px' }} />
              <col style={{ width: '1%', minWidth: '50px' }} />
              <col style={{ width: '7%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '5%' }} />
              <col style={{ width: '5%' }} />
              <col style={{ width: '5%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '5%' }} />
            </colgroup>

            <TableHead>
              <TableRow>
                <TableCell colSpan={13}>
                  <Paper component="form" className={classes.searchField}>
                    <InputBase
                      className={classes.input}
                      placeholder="Meklēt"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchInputValue}
                      onChange={e => setSearchInputValue(e.target.value)}
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={() => onSearchClick(searchInputValue)}
                    >
                      <SearchIcon />
                    </IconButton>
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <SuggestionsExportXlsx filterText={filterText}/>
                  </Paper>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Vieta</TableCell>
                <TableCell align="center">Attēls</TableCell>
                {suggestionTableColumns.map(
                  ({ id, label, sortable }, index) => (
                    <TableCell key={`${id}${index}`}>
                      {sortable ? (
                        <TableSortLabel
                            active={orderBy === id}
                            direction={order}
                          onClick={() => {
                            onOrderByChange(id)
                            onOrderChange()
                          }}
                        >
                          {label}
                        </TableSortLabel>
                      ) : (
                        <p>{label}</p>
                      )}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={suggestionTableColumns.length+2} align="center">
                      Nav Datu
                    </TableCell>
                  </TableRow>
              )}
              <TableBodyContent />
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={onPageChange}
                  onChangeRowsPerPage={onRowsPerPageChange}
                  labelRowsPerPage="Rindas lapā"
                  labelDisplayedRows={({from, to, count}) => `${from}-${to} no ${count}`}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}
