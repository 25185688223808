import React from 'react'
import HistoryBase from './HistoryBase'
import { LIST_NOTIFICATIONS_HISTORY } from '../../../apollo/queries'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'

const keyLabels = {
  dateFrom: 'Datums no',
  dateTo: 'Datums līdz',
  details: 'Teksti',
  sendNotifications: 'Sūtīt notifikācijas',
}

const getStringBetween = (value, start, end) => {
  return value.substring(
    value.lastIndexOf(start) + start.length,
    value.lastIndexOf(end),
  )
}

const keyRenderers = {
  dateFrom: value =>
    value ? format(new Date(value), 'P', { locale: lv }) : '',
  dateTo: value => (value ? format(new Date(value), 'P', { locale: lv }) : ''),
  details: value => {
    if (value) {
      const title = getStringBetween(value, ', title=', ', description=')
      const desctiption = getStringBetween(
        value,
        ', description=',
        ', notificationDescription=',
      )
      const pushDesctiption = getStringBetween(
        value,
        ', notificationDescription=',
        '}]',
      )

      const result = `Nosaukums: ${title} 
      Apraksts: ${desctiption} 
      Push apraksts: ${pushDesctiption}`
      return result
    } else return ''
  },
}

export default function NotificationsHistory() {
  return (
    <HistoryBase
      query={LIST_NOTIFICATIONS_HISTORY}
      queryPath="messages.messages"
      keyLabels={keyLabels}
      keyRenderers={keyRenderers}
      title="Paziņojumu vēsture"
      showOnlyDefinedLabels
    />
  )
}
