import React from 'react'
import {
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Table,
    TableBody,
    TableContainer,
    IconButton,
    TableSortLabel} from '@material-ui/core'
import {Info, Edit, Delete} from "@material-ui/icons"

const NotificationOrderBy = {
  START_TIME: 'dateFrom',
  END_TIME: 'dateTo',
}

const columns = [
  {
    field: 'id',
    title: 'ID',
  },
  {
    field: 'title',
    title: 'Nosaukums',
    cellStyle: {
      minWidth: 100,
    },
  },
  {
    field: 'dateAdded',
    title: 'Pievienošanas laiks',
  },
  {
    field: 'description',
    title: 'Apraksts',
    cellStyle: {
      minWidth: 100,
    },
  },
  {
    field: 'dateFrom',
    title: 'Sākuma laiks',
    sortId: NotificationOrderBy.START_TIME,
  },
  {
    field: 'dateTo',
    title: 'Beigu laiks',
    sortId: NotificationOrderBy.END_TIME,
  },
  {
    field: 'sendNotifications',
    title: 'Sūtīt notifikāciju'
  },
    {
        field: 'feedback',
        title: ''
    },
    {
        field: 'edit',
        title: ''
    },
    {
        field: 'delete',
        title: ''
    },
]

export default function NotificationsTable({
    rows,
    onDelete,
    onRowEdit,
    onShowReview,
    feedbacks,
    page,
    onPageChange,
    rowsPerPage,
    onRowsPerPageChange,
    order,
    onOrderChange,
    orderBy,
    onOrderByChange,
    count,
}) {
    return (
        <TableContainer>
            <Table stickyHeader>
                <colgroup>
                    <col style={{width: '5%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '30%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '5%'}}/>
                    <col style={{width: '5%'}}/>
                    <col style={{width: '5%'}}/>
                </colgroup>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell key={column.field}>
                                {column.sortId ? (
                                    <TableSortLabel
                                        active={column.sortId === orderBy}
                                        direction={order === 'desc' ? 'desc' : 'asc'}
                                        onClick={() => {
                                            onOrderChange()
                                            onOrderByChange(column.sortId)
                                        }}
                                    >
                                        {column.title}
                                    </TableSortLabel>
                                ) : (
                                    column.title
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{row.dateAdded}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.dateFrom}</TableCell>
                                <TableCell>{row.dateTo}</TableCell>
                                <TableCell>{row.sendNotifications}</TableCell>
                                <TableCell>
                                    <Tooltip title="Papildus informācija">
                                        <span>
                                            <IconButton
                                                disabled={!(feedbacks && typeof feedbacks[row.id] !== 'undefined')}
                                                onClick={() => onShowReview(row)}
                                            >
                                                <Info/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Rediģēt rindu">
                                        <IconButton onClick={() => onRowEdit(row)}>
                                            <Edit/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Dzēst rindu">
                                        <IconButton onClick={() => onDelete(row)}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onPageChange}
                onChangeRowsPerPage={onRowsPerPageChange}
                labelDisplayedRows={({from, to, count}) => `${from}-${to} no ${count}`}
                labelRowsPerPage="Rindas lapā"
            />
        </TableContainer>
    )
}
