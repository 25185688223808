import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PlusIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '5px 6px',
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 8,
    '&:hover': {
      borderWidth: 2,
      borderColor: theme.palette.primary.light,
      borderRadius: 8,
      color: theme.palette.primary.light,
      backgroundColor: '#f2f2f2',
    },
  },
  fileInput: {
    display: 'none',
  },
}))

export default function AddFileButton({
  children,
  onChange,
  type,
  multiple = true,
  fileType = 'default',
  ...props
}) {
  const classes = useStyles()

  const fileInputRef = React.createRef()

  const handleBtnClick = () => {
    fileInputRef.current.click()
  }
  const handleSubmit = files => {
    onChange(files)
  }

  return (
    <>
      <input
        multiple={multiple}
        type="file"
        name="addFile"
        hidden
        ref={fileInputRef}
        {...(fileType === 'image'
          ? {
              accept: 'image/png, image/jpeg',
              capture: 'camera',
            }
          : fileType === 'document'
          ? {
              accept:
                'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
          : fileType === 'default'
          ? {
              accept:
                'image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
          : {})}
        onChange={e => {
          handleSubmit([...e.target.files])
          e.target.value = null
        }}
      />
      <Button
        variant="outlined"
        className={classes.root}
        {...props}
        onClick={() => {
          handleBtnClick()
        }}
      >
        {type === 'import' ? '' : <PlusIcon />}
        {children}
      </Button>
    </>
  )
}
