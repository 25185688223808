import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UPDATE_SUGGESTION } from '../../../apollo/mutations'

export default function SuggestionDueDateRenderer({
  id,
  plannedDueDate,
  onSuccess,
  onError,
}) {
  const [date, setDate] = useState(
    plannedDueDate ? new Date(plannedDueDate) : null,
  )
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = value => {
    updateSuggestion({
      variables: {
        id,
        input: {
          plannedDueDate: value.format(),
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          setDate(new Date(data.incidents.update.plannedDueDate))
          onSuccess({ message: `Datums nomainīts` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return (
    <KeyboardDatePicker
      clearable
      value={date}
      onChange={onChange}
      format="DD.MM.YYYY"
      clearLabel="Dzēst"
      cancelLabel="Atcelt"
      okLabel="Saglabāt"
      InputProps={{
        readOnly: true,
      }}
    />
  )
}
