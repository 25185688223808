import React, { useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import SaveOrCancelDialog from './SaveOrCancelDialog'
import { useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { CHANGE_USER_PASSWORD } from '@mcity/core/lib/gql/mutations'

const useStyles = makeStyles({
  subtitle: {
    fontSize: '18px',
    fontWeight: '500',
  },
  text: {
    fontSize: '16px',
  },
})

export default function ChangeOwnPasswordDialog({ setOpen, userInfo }) {
  const classes = useStyles()
  const [changePassword, { loading }] = useMutation(CHANGE_USER_PASSWORD)
  const [complete, setComplete] = useState(false)
  const [alert, setAlert] = useState(null)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({})

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }

  const validateForm = () => {
    const errors = {}
    if (newPassword.length < 8) {
      errors.password = 'Parole nevar būt īsāka par 8 simboliem'
    }
    if (newPassword !== confirmPassword) {
      errors.password = 'Paroles nesakritība'
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onDialogProceed = () => {
    if (validateForm()) {
      changePassword({
        variables: {
          username: userInfo.username,
          newPassword,
        },
      })
        .then(({ data }) => {
          if (data?.passwordAuth?.changePassword) {
            onSuccess({ message: `Parole nomainīta` })
            setComplete(true)
          } else {
            onError({ message: 'Neizdevās nomainīt paroli' })
          }
        })
        .catch(() => {
          onError({ message: 'Pieprasījuma apstrādes kļūda' })
        })
    }
  }

  return (
    <SaveOrCancelDialog
      title={`Lietotājs - ${userInfo.username}`}
      onProceed={onDialogProceed}
      setOpen={setOpen}
      loading={loading}
      complete={complete}
      alert={alert}
      confirmButtonName="Saglabāt"
    >
      <Box p={1} className={classes.text} style={{ textAlign: 'center' }}>
        {userInfo.isActive ? '(Aktīvs)' : '(Neaktīvs)'}
      </Box>
      <Box p={1} className={classes.subtitle}>
        Lietotāja tiesības
      </Box>
      <Box className={classes.text} pl={1}>
        <ul>
          {userInfo.isSuggestionsAdmin && <li>Ierosinājumi</li>}
          {userInfo.isNotificationAdmin && <li>Paziņojumi</li>}
          {userInfo.isAuditAdmin && <li>Audits</li>}
          {userInfo.isUserManagementAdmin && <li>Lietotāji</li>}
        </ul>
      </Box>
      <Box p={1} className={classes.subtitle}>
        Paroles maiņa
      </Box>
      <Box width={300} mb={1}>
        <TextField
          fullWidth
          label="Jaunā parole"
          type="password"
          size="small"
          variant="outlined"
          value={newPassword}
          error={errors?.password?.length > 0}
          helperText={errors.password}
          onChange={event => setNewPassword(event.target.value)}
        />
      </Box>
      <Box width={300} mb={1}>
        <TextField
          fullWidth
          label="Atkārtota jaunā parole"
          type="password"
          size="small"
          variant="outlined"
          value={confirmPassword}
          onChange={event => setConfirmPassword(event.target.value)}
        />
      </Box>
    </SaveOrCancelDialog>
  )
}
