import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import CreateUserDialog from './CreateUserDialog'
import { CREATE_ADMIN_USER } from '@mcity/core/lib/gql/mutations'

export default function CreateAdminUserDialog({ setOpen, onComplete }) {
  const [createAdminUser, { loading }] = useMutation(CREATE_ADMIN_USER)
  const [alert, setAlert] = useState(null)
  const [complete, setComplete] = useState(false)

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }
  const onCreateAdminUser = input => {
    createAdminUser({
      variables: input,
    })
      .then(({ data }) => {
        if (data?.passwordAuth?.createNew?.userId) {
          onSuccess({ message: `Lietotājs izveidots` })
          onComplete()
          setComplete(true)
        } else {
          onError({ message: 'Nav izdevies izveidot lietotāju' })
        }
      })
      .catch(err => {
        console.log(err)
        onError({ message: 'Pieprasījuma apstrādes kļūda' })
      })
  }

  return (
    <CreateUserDialog
      title="Jauna lietotāja izveide"
      onProceed={onCreateAdminUser}
      setOpen={setOpen}
      alert={alert}
      loading={loading}
      complete={complete}
    />
  )
}
