import {useQuery} from "@apollo/client";
import {GET_SUGGESTIONS, GET_SUGGESTIONS_COUNT} from "../apollo/queries";
import {useEffect, useState} from "react";

export const getFilter = (filterText) => {
    return {
        key: "address",
        value: filterText,
        flip: false,
        approximate: true,
        and: null,
        or: {
            key: "title",
            value: filterText,
            flip: false,
            approximate: true,
            and: null,
            or: {
                key: "activities",
                value: filterText,
                flip: false,
                approximate: true,
                and: null,
                or: {
                    key: "base_id",
                    value: filterText,
                    flip: false,
                    approximate: false,
                    and: null,
                    or: null
                }
            }
        }
    }
}

export const useFetchAndReshapeSuggestions = (skip, limit, orderBy, orderAscending, filterText) => {
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setErorr] = useState(null)

    const {loading: countLoading, data: countData, refetch: countRefetch, error: errorCount} = useQuery(GET_SUGGESTIONS_COUNT, {
        fetchPolicy: 'network-only',
        variables: {
            filter: getFilter(filterText)
        }
    })

    const { loading: incidentsLoading, data: incidentsData, refetch: incidentsRefetch, error: errorIncidents } = useQuery(GET_SUGGESTIONS, {
        fetchPolicy: 'network-only',
        variables: {
            limit,
            skip,
            orderBy,
            orderAscending,
            filter: getFilter(filterText)
        }
    })

    useEffect(() => {
        setLoading(true)
        if(!incidentsLoading && !countLoading) {
            if(errorIncidents || errorCount) {
                setErorr("Kļūda iegūstot datus")
            } else {
                setRows(incidentsData?.incidents?.incidents || [])
                setCount(countData?.incidents?.incidentsTotalCount || 0)
            }
            setLoading(false)
        }
    }, [countLoading, countData, incidentsLoading, incidentsData])

    const refetch = () => {
        const promise1 = incidentsRefetch();
        const promise2 = countRefetch();
        return Promise.allSettled([promise1, promise2])
    }

    return {rows, loading, count, refetch, error}
}
