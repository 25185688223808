import React from 'react'
import * as moment from 'moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/lv'
import MomentUtils from '@date-io/moment'
import ApolloProvider from './components/providers/ApolloProvider'
import ThemeProvider from './components/providers/ThemeProvider'
import { ModalProvider } from 'react-modal-hook'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import routes from './routes/routes'
import SignIn from './components/views/SignIn'
import LiepajaConfigProvider from '@mcity/liepaja-core/src/components/providers/LiepajaConfigProvider'
import PasswordAuthProvider from './components/providers/PasswordAuthProvider'
import {
  SuggestionsListView,
  SuggestionsMapView,
} from './components/views/Suggestions'
import TopBar from './components/views/TopBar'
import Notifications from './components/views/Notifications'
import SuggestionHistory from './components/views/history/SuggestionHistory'
import NotificationHistory from './components/views/history/NotificationHistory'
import LiepajaAdminAuthProvider, {
  useLiepajaAdminAuth,
} from './components/providers/LiepajaAdminAuthProvider'
import SnackBarProvider from '@mcity/core/lib/components/providers/SnackBarProvider'
import UserManagement from './components/views/UserManagement'
import { AppStateProvider } from '@mcity/liepaja-core/src/components/providers/AppStateProvider'
import { OfflineNotice } from '@mcity/liepaja-core/src/components/elements/error/OfflineNotice'

function AdminRoute({ children, ...rest }) {
  const { isAnyAdmin } = useLiepajaAdminAuth()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAnyAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

export default function App() {
  return (
    <LiepajaConfigProvider>
      <Router>
        <AppStateProvider>
          <ApolloProvider>
            <PasswordAuthProvider>
              <LiepajaAdminAuthProvider>
                <ThemeProvider>
                  <SnackBarProvider>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                    >
                      <ModalProvider>
                        <Switch>
                          <Route path={routes.login}>
                            <SignIn />
                          </Route>
                          <AdminRoute exact path={routes.home}>
                            <TopBar>
                              <SuggestionsListView />
                            </TopBar>
                          </AdminRoute>
                          <AdminRoute exact path={routes.notifications.list}>
                            <TopBar>
                              <Notifications />
                            </TopBar>
                          </AdminRoute>
                          <AdminRoute exact path={routes.suggestions.list}>
                            <TopBar>
                              <SuggestionsListView />
                            </TopBar>
                          </AdminRoute>
                          <AdminRoute exact path={routes.suggestions.map}>
                            <TopBar>
                              <SuggestionsMapView />
                            </TopBar>
                          </AdminRoute>
                          <AdminRoute exact path={routes.history.suggestions}>
                            <TopBar>
                              <SuggestionHistory />
                            </TopBar>
                          </AdminRoute>
                          <AdminRoute exact path={routes.history.notifications}>
                            <TopBar>
                              <NotificationHistory />
                            </TopBar>
                          </AdminRoute>
                          <AdminRoute exact path={routes.user_management}>
                            <TopBar>
                              <UserManagement />
                            </TopBar>
                          </AdminRoute>
                        </Switch>
                      </ModalProvider>
                      <OfflineNotice />
                    </MuiPickersUtilsProvider>
                  </SnackBarProvider>
                </ThemeProvider>
              </LiepajaAdminAuthProvider>
            </PasswordAuthProvider>
          </ApolloProvider>
        </AppStateProvider>
      </Router>
    </LiepajaConfigProvider>
  )
}
