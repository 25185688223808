import React, { useEffect, useState } from 'react'
import { usePasswordAuth } from './PasswordAuthProvider'

export const IS_AUDIT_ADMIN = 'isAuditAdmin'
export const IS_NOTIFICATION_ADMIN = 'isMessagesAdmin'
export const IS_SUGGESTION_ADMIN = 'isSuggestionsAdmin'
export const IS_USERMANAGEMENT_ADMIN = 'isUserManagementAdmin'
export const IS_ACTIVE = 'isActive'

const Context = React.createContext(null)

export function useLiepajaAdminAuth() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'useLiepajaAdminAuth must be used within a LiepajaAdminAuthProvider',
    )
  }
  return context
}

export default function LiepajaAdminAuthProvider({ children }) {
  const { isTrue, params, username } = usePasswordAuth()

  const [isSuggestionsAdmin, setIsSuggestionsAdmin] = useState(
    isTrue(IS_SUGGESTION_ADMIN),
  )
  const [isAuditAdmin, setIsAuditAdmin] = useState(isTrue(IS_AUDIT_ADMIN))
  const [isNotificationAdmin, setIsNotificationAdmin] = useState(
    isTrue(IS_NOTIFICATION_ADMIN),
  )
  const [isUserManagementAdmin, setIsUserManagementAdmin] = useState(
    isTrue(IS_USERMANAGEMENT_ADMIN),
  )
  const [isActive, setIsActive] = useState(isTrue(IS_ACTIVE))

  const [isAnyAdmin, setIsAnyAdmin] = useState(
    isNotificationAdmin ||
      isSuggestionsAdmin ||
      isAuditAdmin ||
      isUserManagementAdmin,
  )

  const reload = () => {
    const isNotificationAdmin = isTrue(IS_NOTIFICATION_ADMIN)
    const isAuditAdmin = isTrue(IS_AUDIT_ADMIN)
    const isSuggestionsAdmin = isTrue(IS_SUGGESTION_ADMIN)
    const isUserManagementAdmin = isTrue(IS_USERMANAGEMENT_ADMIN)
    const isActive = isTrue(IS_ACTIVE)

    const isAnyAdmin =
      isNotificationAdmin ||
      isSuggestionsAdmin ||
      isAuditAdmin ||
      isUserManagementAdmin

    setIsActive(isActive)
    setIsNotificationAdmin(isNotificationAdmin)
    setIsAuditAdmin(isAuditAdmin)
    setIsSuggestionsAdmin(isSuggestionsAdmin)
    setIsUserManagementAdmin(isUserManagementAdmin)
    setIsAnyAdmin(isAnyAdmin)
  }

  useEffect(() => {
    reload()
  }, [params])

  return (
    <Context.Provider
      value={{
        username,
        isActive,
        isAnyAdmin,
        isAuditAdmin,
        isNotificationAdmin,
        isSuggestionsAdmin,
        isUserManagementAdmin,
        reload,
      }}
    >
      {children}
    </Context.Provider>
  )
}
