import React, { useState } from 'react'
import { Box, Checkbox } from '@material-ui/core'
import SaveOrCancelDialog from './SaveOrCancelDialog'
import { useMutation, useQuery } from '@apollo/client'
import {
  IS_ACTIVE,
  IS_AUDIT_ADMIN,
  IS_NOTIFICATION_ADMIN,
  IS_SUGGESTION_ADMIN,
  IS_USERMANAGEMENT_ADMIN,
} from '../providers/LiepajaAdminAuthProvider'
import { CHANGE_USER_PARAMS } from '@mcity/core/lib/gql/mutations'
import { GET_ONE_USER } from '@mcity/core/lib/gql/queries'

const createLabel = key => {
  switch (key) {
    case IS_ACTIVE:
      return 'Aktīvs lietotājs'
    case IS_AUDIT_ADMIN:
      return 'Auditi'
    case IS_NOTIFICATION_ADMIN:
      return 'Paziņojumi'
    case IS_SUGGESTION_ADMIN:
      return 'Ierosinājumi'
    case IS_USERMANAGEMENT_ADMIN:
      return 'Lietotāji'
    default:
      return key
  }
}

let indexForIsActiveParam = - 1;

export default function ChangeUsersParamsDialog({
  setOpen,
  id,
  username,
  onComplete,
}) {
  const [updateParams, { loadingParams }] = useMutation(CHANGE_USER_PARAMS)
  const [complete, setComplete] = useState(false)
  const [alert, setAlert] = useState(null)
  const [userParams, setUserParams] = useState(null)
  const [loadingData, setLoadingData] = useState(true)
  useQuery(GET_ONE_USER, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
    },
    onCompleted: data => {
      const result = data?.authorizationUsers?.userById
      if (result) {
        const params = result.params.map(item => {
          return {
            key: item.key,
            value: item.value,
          }
        })
        indexForIsActiveParam = params.findIndex(item => item.key === IS_ACTIVE);
        setUserParams(params)
        setLoadingData(false)
      }
    },
  })

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }

  const onDialogProceed = () => {
    updateParams({
      variables: {
        input: {
          userId: id,
          username,
          params: userParams.map(({ key, value }) => {
            return {
              key,
              value,
              isEditable: true,
            }
          }),
        }
      },
    })
      .then(({ data }) => {
        if (data?.passwordAuth?.changePasswordAuthParams) {
          onSuccess({ message: `Parametri nomainīti` })
          setComplete(true)
          onComplete()
        } else {
          onError({ message: 'Neizdevās nomainīt parametrus' })
        }
      })
      .catch(err => {
        console.log(err)
        onError({ message: 'Pieprasījuma apstrādes kļūda' })
      })
  }

  return (
    <SaveOrCancelDialog
      title={`Tiesību maiņa lietotājam ${username}`}
      onProceed={onDialogProceed}
      setOpen={setOpen}
      loading={loadingParams}
      complete={complete}
      alert={alert}
      confirmButtonName="Saglabāt"
    >
      {loadingData ? (
        <div>LOADING...</div>
      ) : (
          <Box style={{display: "flex", flexDirection: "column"}}>
            <Box>
              {userParams.map((item, index) => {
                if(index !== indexForIsActiveParam)
                  return (<Box pl={1} key={index}>
                      <Checkbox
                          checked={item.value === 'true'}
                          onChange={() => {
                            const updatedParams = [...userParams]
                            updatedParams[index].value === 'true'
                                ? (updatedParams[index].value = 'false')
                                : (updatedParams[index].value = 'true')
                            setUserParams(updatedParams)
                          }}
                      />
                      <span>{createLabel(item.key)}</span>
                    </Box>)}
                    )}
            </Box>
            {indexForIsActiveParam >= 0 && (
              <Box style={{alignSelf: 'flex-end'}}>
                <span>{createLabel(userParams[indexForIsActiveParam].key)}</span>
                <Checkbox
                    checked={userParams[indexForIsActiveParam].value === 'true'}
                    onChange={() => {
                      const updatedParams = [...userParams]
                      updatedParams[indexForIsActiveParam].value === 'true'
                          ? (updatedParams[indexForIsActiveParam].value = 'false')
                          : (updatedParams[indexForIsActiveParam].value = 'true')
                      setUserParams(updatedParams)
                    }}
                />
              </Box>)
            }
          </Box>
      )}
    </SaveOrCancelDialog>
  )
}
