import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import SaveOrCancelDialog from '../SaveOrCancelDialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import UploadMultipleFiles from '../attachments/UploadMultipleFiles'
import RichTextEditor from 'react-rte'

const defaultErrorMsg = 'Lauks ir obligāti jāaizpilda.'
export default function NotificationEditor({
  notification,
  setNotification,
  complete,
  alert,
  loading,
  setOpen,
  executeUpload,
  onUploadFinished,
}) {
  const [formState, setFormState] = React.useState({
    title: '',
    description: RichTextEditor.createEmptyValue(),
    notificationDescription: '',
    dateFrom: null,
    dateTo: null,
    sendNotifications: false,
  })
  const [errors, setErrors] = React.useState({})
  const [filesSaved, setFilesSaved] = React.useState(false)
  const [notificationId, setNotificationId] = React.useState()
  const isEditMode = Boolean(notification)

  const isFormValid = () => {
    const _errors = []
    if (!formState.title) _errors.title = defaultErrorMsg
    if (!formState.description) _errors.description = defaultErrorMsg
    if (formState.sendNotifications && !formState.notificationDescription)
      _errors.notificationDescription = defaultErrorMsg
    if (!formState.dateFrom) _errors.dateFrom = defaultErrorMsg
    // TODO: dateTo should be optional (backend)
    if (!formState.dateTo) _errors.dateTo = defaultErrorMsg

    setErrors(_errors)
    return Object.keys(_errors).length === 0
  }
  const onCreateNotification = () => {
    if (!isFormValid()) return
    setNotification({
      ...formState,
      description: formState.description.toString('html'),
    })
  }

  React.useEffect(() => {
    if (notification) {
      setNotificationId(notification.id)
    }
  }, [notification])

  React.useEffect(() => {
    if (filesSaved) {
      onUploadFinished()
    }
  }, [filesSaved])

  React.useEffect(() => {
    if (notification) {
      const displayableDetails = notification.details.find((d) => {return d.languageId === 1})
      setFormState(_formState => ({
        title: displayableDetails.title,
        dateFrom: notification.dateFrom,
        dateTo: notification.dateTo,
        sendNotifications: notification.sendNotifications,
        description: RichTextEditor.createValueFromString(
          displayableDetails.description,
          'html',
        ),
        notificationDescription: displayableDetails.notificationDescription,
      }))
    }
  }, [notification])

  return (
    <SaveOrCancelDialog
      title={isEditMode ? 'Atjaunināt paziņojumu' : 'Jauns paziņojums'}
      onProceed={onCreateNotification}
      setOpen={setOpen}
      complete={complete}
      alert={alert}
      loading={loading}
    >
      <Box display="flex" flexDirection="column" spacing={3}>
        <TextField
          margin="normal"
          fullWidth
          size="small"
          label="Nosaukums"
          variant="outlined"
          value={formState.title}
          error={Boolean(errors.title)}
          helperText={errors.title}
          onChange={({ target: { value } }) =>
            setFormState(_formState => ({ ..._formState, title: value }))
          }
        />
        <Box mb={1} mt={1}>
          <RichTextEditor
            placeholder="Apraksts"
            value={formState.description}
            onChange={value => {
              setFormState(_formState => ({
                ..._formState,
                description: value,
              }))
            }}
          />
        </Box>
        <TextField
          margin="normal"
          fullWidth
          multiline
          size="small"
          label="Push paziņojuma teksts"
          required={formState.sendNotifications}
          variant="outlined"
          value={formState.notificationDescription}
          error={Boolean(errors.notificationDescription)}
          helperText={errors.notificationDescription}
          onChange={({ target: { value } }) =>
            setFormState(_formState => ({
              ..._formState,
              notificationDescription: value,
            }))
          }
        />
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <KeyboardDatePicker
            autoOk
            size="small"
            label="Sākuma laiks"
            variant="inline"
            inputVariant="outlined"
            value={formState.dateFrom}
            onChange={value =>
              setFormState(_formState => ({
                ..._formState,
                dateFrom: value.format(),
              }))
            }
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: 'start' }}
            error={errors.dateFrom}
            // helperText={errors.dateFrom}
          />
          <p style={{ marginLeft: 10, marginRight: 10 }}>līdz</p>
          <KeyboardDatePicker
            autoOk
            size="small"
            label="Beigu laiks"
            variant="inline"
            inputVariant="outlined"
            value={formState.dateTo}
            onChange={value =>
              setFormState(_formState => ({
                ..._formState,
                dateTo: value.format(),
              }))
            }
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: 'start' }}
            error={errors.dateTo}
            // helperText={errors.dateTo}
          />
        </Box>
        <Box>
          <UploadMultipleFiles
            title="Faili"
            objectId={notificationId}
            type="default"
            moduleName="notifications"
            setSaved={setFilesSaved}
            executeSave={!filesSaved && executeUpload && notificationId > 0}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.sendNotifications}
              color="primary"
              onChange={({ target: { checked } }) => {
                setFormState(_formState => ({
                  ..._formState,
                  sendNotifications: checked,
                }))
              }}
            />
          }
          label="Push paziņojums"
        />
      </Box>
    </SaveOrCancelDialog>
  )
}
