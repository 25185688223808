import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  previousValue: {
    color: 'red',
    fontWeight: 'bold',
    backgroundColor: 'pink',
    whiteSpace: 'pre-line',
  },
  currentValue: {
    color: 'green',
    fontWeight: 'bold',
    backgroundColor: 'lightgreen',
    whiteSpace: 'pre-line',
  },
  emptyPreviousValue: {
    color: 'red',
    fontWeight: 'bold',
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: 'pink',
  },
})

export default function DifferencesRenderer({
  differences,
  keyLabels,
  keyRenderers,
  showOnlyDefinedLabels = false,
}) {
  const classes = useStyles()
  return differences && differences.length ? (
    <Box display="flex" flexDirection="column">
      {differences.map(({ key, previousValue, currentValue }) => {
        if (
          (showOnlyDefinedLabels && keyLabels[key]) ||
          !showOnlyDefinedLabels
        ) {
          const render = value =>
            keyRenderers && keyRenderers[key] ? keyRenderers[key](value) : value
          return (
            <Box key={`diff-${key}`}>
              <span>{keyLabels[key] || key}: </span>
              <span
                className={
                  previousValue
                    ? classes.previousValue
                    : classes.emptyPreviousValue
                }
              >
                {render(previousValue) || '-'}
              </span>
              <span> ⟶ </span>
              <span className={classes.currentValue}>
                {render(currentValue)}
              </span>
            </Box>
          )
        }
      })}
    </Box>
  ) : (
    ''
  )
}
