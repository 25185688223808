import {useMutation, useQuery} from '@apollo/client'
import React, {useState, useMemo, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import NotificationsTable from '../elements/notifications/NotificationsTable'
import NotificationCreateDialog from '../elements/notifications/NotificationCreateDialog'
import NotificationFeedbackDialog from '../elements/notifications/NotificationFeedbackDialog'
import { DELETE_NOTIFICATION } from '../../apollo/mutations'
import ConfirmDialog from '../elements/ConfirmDialog'
import { useModal } from 'react-modal-hook'
import { useSnackBar } from '@mcity/core/lib/components/providers/SnackBarProvider'
import {useFetchAndReshapeNotifications} from "../../hooks/useFetchAndReshapeNotifications";
import Loader from "../elements/LoaderOverlay";
import {GET_FEEDBACK_FOR_IDS} from "../../apollo/queries";

export default function Notifications() {
  const { showSnackBar } = useSnackBar()
  const [showCreateNotification, setShowCreateNotification] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [selectedFeedbackNotificationId, setSelectedFeedbackNotificationId] = useState(null)
  const [selectedNotificationIdToDelete, setSelectedNotificationIdToDelete] = useState(null)
  const [selectedNotificationId, setSelectedNotificationId] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState("dateFrom")
  const [feedbackMap, setFeedbackMap] = useState({})

  const {rows, loading, count, refetch} = useFetchAndReshapeNotifications(page*rowsPerPage, rowsPerPage, orderBy, order === "asc")

  const onPageChange = (_, newPage) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  const onOrderByChange = (value) => {
    setOrderBy(value)
  }

  const onOrderChange = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const onDelete = (row) => {
    setSelectedNotificationIdToDelete(row.id)
    showConfirmDialog()
  }

  const onRowEdit = (row) => {
    setSelectedNotificationId(row.id)
    setShowCreateNotification(true)
  }

  const onShowReview = (row) => {
    setSelectedFeedbackNotificationId(row.id)
    setShowFeedback(true)
  }

  const feedbackFilter = useMemo(() => {
    let filter = null
    if (rows) {
      rows.forEach(row => {
        filter = {
          key: 'messageId',
          value: row.id,
          approximate: false,
          flip: false,
          or: filter,
        }
      })
    }
    return filter
  }, [rows])

  const { data: dataFeedbacks } = useQuery(
      GET_FEEDBACK_FOR_IDS,
      {
        variables: {
          filter: feedbackFilter,
        },
        skip: feedbackFilter === null,
      },
  )

  useEffect(() => {
    const map = {}
    if (dataFeedbacks?.feedback?.responses) {
      dataFeedbacks.feedback.responses.forEach(f => {
        if (!map[f.messageId]) {
          map[f.messageId] = []
        }
        map[f.messageId] = [...map[f.messageId], f]
      })
    }
    setFeedbackMap(map)
  }, [dataFeedbacks])

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION)

  const [showConfirmDialog, hideConfirmDialog] = useModal(() => {
    const hideDialog = () => {
      setSelectedNotificationId(null)
      hideConfirmDialog()
    }

    return (
      selectedNotificationIdToDelete && (
        <ConfirmDialog
          text="Vai tiešām vēlaties dzēst šo paziņojumu?"
          onClose={hideDialog}
          onAccept={() => {
            deleteNotification({
              variables: {
                id: selectedNotificationIdToDelete,
              },
            })
              .then(response => {
                if (response?.data?.messages?.messages?.deleteMessage) {
                  refetch()
                  showSnackBar({
                    text: 'Paziņojums izdzēsts',
                  })
                }
              })
              .catch(err =>
                showSnackBar({
                  severity: 'error',
                  text: `kļūda! ${JSON.stringify(err)}`,
                }),
              )
              .finally(() => {
                hideDialog()
              })
          }}
          onCancel={hideDialog}
        />
      )
    )
  }, [selectedNotificationIdToDelete])

  return (
    <>
      {loading && <Loader />}
      {showCreateNotification && (
        <NotificationCreateDialog
          notificationId={selectedNotificationId}
          onComplete={() => {
            refetch()
          }}
          setOpen={setShowCreateNotification}
        />
      )}
      {showFeedback && (
        <NotificationFeedbackDialog
          feedback={feedbackMap[selectedFeedbackNotificationId]}
          setOpen={setShowFeedback}
        />
      )}
      <Box
        px={2}
        mt={1}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedNotificationId(null)
            setShowCreateNotification(true)
          }}
        >
          Pievienot
        </Button>
      </Box>
      <NotificationsTable
        onRowEdit={onRowEdit}
        onShowReview={onShowReview}
        onDelete={onDelete}
        rows={rows}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        order={order}
        onOrderChange={onOrderChange}
        orderBy={orderBy}
        onOrderByChange={onOrderByChange}
        feedbacks={feedbackMap}
        count={count}
      />
    </>
  )
}
