import { useEffect, useState } from 'react'
import XLSX from 'xlsx'

const useExportXlsx = (
  data,
  onComplete = () => {},
  file_name = 'xlsx',
  title = 'xlsx',
  subject = 'dati',
  merges,
) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (data) {
      setLoading(true)
      const ws = XLSX.utils.aoa_to_sheet(data)
      if (merges) {
        if (!ws['!merges']) ws['!merges'] = merges
      }

      const wb = XLSX.utils.book_new()
      wb.Props = {
        Title: title,
        Subject: subject,
        Author: 'Liepaja',
        CreatedDate: new Date(),
      }
      wb.SheetNames.push('1')
      wb.Sheets['1'] = ws

      XLSX.writeFile(wb, `${file_name}.xlsx`, { compression: true })
      setLoading(false)
      onComplete()
    }
  }, [data])
  return { loading }
}
export default useExportXlsx
