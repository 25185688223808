import { useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useState } from 'react'
import SuggestionsTable from '../elements/suggestions/SuggestionsTable'
import SuggestionsMap from '../elements/suggestions/SuggestionsMap'
import { GET_SUGGESTIONS_SHORT } from '../../apollo/queries'
import Error from '@mcity/core/lib/components/elements/Error'
import { useModal } from 'react-modal-hook'
import {SuggestionDeleteConfirmDialog} from "../elements/suggestions/SuggestionDeleteConfirmDialog";
import {useFetchAndReshapeSuggestions} from "../../hooks/useFetchAndReshapeSuggestions";
import Loader from "../elements/LoaderOverlay";

const INCIDENT_FILTER_MAP = {
  key: 'status',
  value: 'DONE',
  approximate: false,
  flip: false,
  or: {
    key: 'status',
    value: 'NEW',
    approximate: false,
    flip: false,
    or: {
      key: 'status',
      value: 'STARTED',
      approximate: false,
      flip: false,
      or: {
        key: 'status',
        value: 'ACCEPTED',
        approximate: false,
        flip: false,
      },
    },
  },
}

export function SuggestionsMapView() {
  const [
    selectedSuggestionIdToDelete,
    setSelectedSuggestionIdToDelete,
  ] = useState(null)

  const { data, loading, refetch } = useQuery(GET_SUGGESTIONS_SHORT, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 1000,
      skip: 0,
      filter: INCIDENT_FILTER_MAP,
    },
  })

  const [showConfirmDialog, hideConfirmDialog] = useModal(() => {
    const hideDialog = () => {
      hideConfirmDialog()
    }
    return (
      selectedSuggestionIdToDelete ?
        <SuggestionDeleteConfirmDialog
          hideDialog={hideDialog}
          refetch={refetch}
          selectedSuggestionIdToDelete={selectedSuggestionIdToDelete}
        /> : null)
  }, [selectedSuggestionIdToDelete])

  if (loading) {
    return <CircularProgress />
  }

  if (!data?.incidents?.incidents) {
    return <Error>No data to display</Error>
  }

  return (
      <div style={{height: "100vh"}}>
        <SuggestionsMap
            onDelete={suggestionId => {
              setSelectedSuggestionIdToDelete(suggestionId)
              showConfirmDialog()
            }}
            suggestions={data.incidents.incidents}
        />
      </div>
  )
}

export function SuggestionsListView() {
  const [
    selectedSuggestionIdToDelete,
    setSelectedSuggestionIdToDelete,
  ] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState("id")
  const [filterText, setFilterText] = useState("")

  const { rows, count, loading, refetch: refetchTableData } = useFetchAndReshapeSuggestions(
    page * rowsPerPage, rowsPerPage, orderBy, order === "asc", filterText)

  const onPageChange = (_, newPage) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const onOrderByChange = (value) => {
    setOrderBy(value)
  }

  const onOrderChange = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const onSearchClick = (input) => {
    setPage(0)
    setFilterText(input.trim())
  }

  const [showConfirmDialog, hideConfirmDialog] = useModal(() => {
    const hideDialog = () => {
      hideConfirmDialog()
    }
    return (
        selectedSuggestionIdToDelete ?
            <SuggestionDeleteConfirmDialog
                hideDialog={hideDialog}
                refetch={refetchTableData}
                selectedSuggestionIdToDelete={selectedSuggestionIdToDelete}
            /> : null)
  }, [selectedSuggestionIdToDelete])

  return (
      <>
        {loading && <Loader />}
        <SuggestionsTable
          rows={rows}
          onDelete={suggestionId => {
            setSelectedSuggestionIdToDelete(suggestionId)
            showConfirmDialog()
          }}
          refetchTableData={refetchTableData}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          filterText={filterText}
          onSearchClick={onSearchClick}
          onOrderByChange={onOrderByChange}
          orderBy={orderBy}
          onOrderChange={onOrderChange}
          order={order}
          count={count}
          loading={loading}
        />
      </>
  )
}
