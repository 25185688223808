import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MuiAlert from '@material-ui/lab/Alert'
import { useHistory } from 'react-router-dom'
import routes from '../../routes/routes'
import LoginForm from '../elements/LoginForm'
import LiepajaLogo from '../../../../mcity-liepaja-core/src/components/elements/logo/LiepajaLogo'
import {
  IS_AUDIT_ADMIN,
  IS_NOTIFICATION_ADMIN,
  IS_SUGGESTION_ADMIN, IS_USERMANAGEMENT_ADMIN
} from "../providers/LiepajaAdminAuthProvider";

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    paddingTop: '23px',
    width: '133px',
    height: '44px',
    margin: '0 auto',
  },
})

const isTrue = (params, paramName) => {
  return (
      params &&
      Object.values(params).find(p => p.key === paramName)?.value === 'true'
  )
}

export default function SignIn() {
  const classes = useStyles()
  const history = useHistory()
  const [alert, setAlert] = useState(history?.location?.state?.alert)

  function onLoginSuccess(user) {
    if (user.params) {
      if (isTrue(user.params, IS_SUGGESTION_ADMIN)) {
        return history.replace(routes.suggestions.list)
      }
      if (isTrue(user.params, IS_NOTIFICATION_ADMIN)) {
        return history.replace(routes.notifications.list)
      }
      if (isTrue(user.params, IS_AUDIT_ADMIN)) {
        return history.replace(routes.history.suggestions)
      }
      if (isTrue(user.params, IS_USERMANAGEMENT_ADMIN)) {
        return history.replace(routes.user_management)
      }
    }
    setAlert({
      severity: 'error',
      message: 'Šīm lietotājam nav administrēšanas tiesību',
    })
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item>
        <div className={classes.paper}>
          <div className={classes.logo}>
            <LiepajaLogo />
          </div>
          <LoginForm onSuccess={onLoginSuccess} />
          <div>
            {alert && (
              <MuiAlert
                onClose={() => setAlert(null)}
                severity={alert ? alert.severity : 'info'}
              >
                {alert ? alert.message : ''}
              </MuiAlert>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
