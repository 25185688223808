import React, {useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {suggestionTableColumns} from "./SuggestionsTable";
import TableCell from "@material-ui/core/TableCell";
import SuggestionEdit from "./SuggestionEdit";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {useQuery} from "@apollo/client";
import {GET_ONE_SUGGESTION} from "../../../apollo/queries";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "@mcity/core/lib/components/elements/Error";

export const SuggestionsMapEditSuggestionDialog = ({
    onDialogClose,
    id,
    onSuccess,
    onError,
    onDelete,
    onOpenDescription,
}) => {
    const { data, error, loading} = useQuery(GET_ONE_SUGGESTION, {
        variables: {
            id,
        },
    })

    return (
        <Dialog
            open
            onClose={onDialogClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle id="form-dialog-title">Ierosinājuma labošana</DialogTitle>
            {loading && <CircularProgress />}
            {error && <Error>{error.message}</Error>}
            {data && <DialogContent>
                    <Table stickyHeader aria-label="simple table">
                        <colgroup>
                            <col style={{ width: '1%', minWidth: '70px' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                {suggestionTableColumns.map(({ label }, index) => (
                                    <TableCell key={index}>{label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <SuggestionEdit
                            suggestion={data?.incidents?.incident}
                            onSuccess={onSuccess}
                            onError={onError}
                            onDelete={onDelete}
                            openDescription={() => onOpenDescription(data?.incidents?.incident)}
                        />
                    </Table>
                </DialogContent>}
            <DialogActions>
                <Button onClick={onDialogClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}