import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import {
  StyledMenu,
  StyledMenuItem,
} from '@mcity/core/lib/components/elements/topbar/StyledMenu'
import ListItemText from '@material-ui/core/ListItemText'

export function ChangeUsersInfoButton({
  items,
  setNextWindow,
  setOpenNextWindow,
}) {
  const [openDropdown, setOpenDropdown] = useState(null)

  return (
    <>
      <IconButton
        onClick={event => {
          setOpenDropdown(true)
          setOpenDropdown(event.currentTarget)
        }}
      >
        <EditIcon />
      </IconButton>
      <StyledMenu
        anchorEl={openDropdown}
        keepMounted
        open={Boolean(openDropdown)}
        onClose={() => {
          setOpenDropdown(null)
          setNextWindow(null)
        }}
      >
        {items.map((dropDownItem, index) => (
          <StyledMenuItem
            onClick={() => {
              setNextWindow(dropDownItem.value)
              setOpenNextWindow(true)
              setOpenDropdown(null)
            }}
            key={index}
          >
            <ListItemText primary={dropDownItem.label} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
